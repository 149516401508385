// RETRIEVE TEMPLATES
export const getTemplateCurl = `curl --include \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}'`;
export const getTemplateJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}")
  .request(MediaType.TEXT_PLAIN_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .get();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const getTemplateJavascript = `var request = new XMLHttpRequest();

request.open('GET', 'https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}');

request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const getTemplateNode = `var request = require('request');

request({
  method: 'GET',
  url: 'https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}',
  headers: {
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const getTemplatePerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->get("https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}");

print $response->as_string;`;
export const getTemplatePython = `from urllib2 import Request, urlopen

headers = {
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}', headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const getTemplatePhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const getTemplateRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.get 'https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}', headers
puts response`;
export const getTemplateGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("GET", "https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}", nil)

\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const getTemplateC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
  using(var response = await httpClient.GetAsync("nodes/templates/{AccountName}/My Templates/{EntityDefinition}{?acl,eprop}"))
  {
 
        string responseData = await response.Content.ReadAsStringAsync();
  }
}`;
export const getTemplateVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}"), System.Net.HttpWebRequest)

request.Method = "GET"

request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const getTemplateGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

response = client.get( path : "/nodes/templates/{AccountName}/My Templates/{EntityDefinition}{?acl,eprop}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const getTemplateObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"GET"];

[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const getTemplateSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/templates/{AccountName}/My Templates/{EntityDefinition}")!
var request = URLRequest(url: url)
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// CREATE TEMPLATES
export const createTemplateRaw = `Headers:

Authorization:Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==
Accept:application/json

Body:

{
  "applies_to": "SmartVault.Accounting.TaxEngagement",
  "api_name": "TaxEngagementCustomTemplate",
  "label": "My Custom Tax Engagement Template",
  "groups": [
    {
      "moniker": "FirmManager",
      "name": "Firm Managers",
      "rid": 8,
      "create": true
    },
    {
      "moniker": "AccountEmployees",
      "name": "Employees",
      "rid": 18,
      "create": false
    }
  ],
  "folders": [
    {
      "name": "Tax Year 2012",
      "acl": {
        "aces": [
          {
            "principal": "AccountEmployees",
            "permissions": 15
          },
          {
            "principal": "FirmManager",
            "permissions": 111
          }
        ]
      },
      "id": "root",
      "folders": [
        {
          "name": "Client Tax Returns",
          "acl": {
            "aces": [
              {
                "principal": "FirmEmployees",
                "permissions": 15
              }
            ]
          },
          "id": "client_tax_returns",
          "folder_association": {
            "tags": [
              {
                "value": "clientcopy.taxreturn.tags.accounting.smartvault.com"
              }
            ]
          }
        }
      ]
    }
  ]
}`;
export const createTemplateCurl = `curl --include \\
     --request PUT \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
     --data-binary "{
    \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",
    \\"api_name\\": \\"TaxEngagementCustomTemplate\\",
    \\"label\\": \\"My Custom Tax Engagement Template\\",
    \\"groups\\": [
        {
            \\"moniker\\": \\"FirmManager\\",
            \\"name\\": \\"Firm Managers\\",
            \\"rid\\": 8,
            \\"create\\": true
        },
        {
            \\"moniker\\": \\"AccountEmployees\\",
            \\"name\\": \\"Employees\\",
            \\"rid\\": 18,
            \\"create\\": false
        }
    ],
    \\"folders\\": [
        {
            \\"name\\": \\"Tax Year 2012\\",
            \\"acl\\": {
                \\"aces\\": [
                    {
                        \\"principal\\": \\"AccountEmployees\\",
                        \\"permissions\\": 15
                    },
                    {
                        \\"principal\\": \\"FirmManager\\",
                        \\"permissions\\": 111
                    }
                ]
            },
            \\"id\\": \\"root\\",
            \\"folders\\": [
                {
                    \\"name\\": \\"Client Tax Returns\\",
                    \\"acl\\": {
                        \\"aces\\": [
                            {
                                \\"principal\\": \\"FirmEmployees\\",
                                \\"permissions\\": 15
                            }
                        ]
                    },
                    \\"id\\": \\"client_tax_returns\\",
                    \\"folder_association\\": {
                        \\"tags\\": [
                            {
                                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"
                            }
                        ]
                    }
                }
            ]
        }
    ]
}" \\
'https://rest.smartvault.com/nodes/templates/AccountName/My Templates'`;
export const createTemplateJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity<String> payload = Entity.text("{  \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",  \\"api_name\\": \\"TaxEngagementCustomTemplate\\",  \\"label\\": \\"My Custom Tax Engagement Template\\",  \\"groups\\": [    {      \\"moniker\\": \\"FirmManager\\",      \\"name\\": \\"Firm Managers\\",      \\"rid\\": 8,      \\"create\\": true    },    {      \\"moniker\\": \\"AccountEmployees\\",      \\"name\\": \\"Employees\\",      \\"rid\\": 18,      \\"create\\": false    }  ],  \\"folders\\": [    {      \\"name\\": \\"Tax Year 2012\\",      \\"acl\\": {        \\"aces\\": [          {            \\"principal\\": \\"AccountEmployees\\",            \\"permissions\\": 15          },          {            \\"principal\\": \\"FirmManager\\",            \\"permissions\\": 111          }        ]      },      \\"id\\": \\"root\\",      \\"folders\\": [        {          \\"name\\": \\"Client Tax Returns\\",          \\"acl\\": {            \\"aces\\": [              {                \\"principal\\": \\"FirmEmployees\\",                \\"permissions\\": 15              }            ]          },          \\"id\\": \\"client_tax_returns\\",          \\"folder_association\\": {            \\"tags\\": [              {                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"              }            ]          }        }      ]    }  ]}");
Response response = client.target("https://rest.smartvault.com/nodes/templates/AccountName/My Templates")
  .request(MediaType.TEXT_PLAIN_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .put(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const createTemplateJavascript = `var request = new XMLHttpRequest();

request.open('PUT', 'https://rest.smartvault.com/nodes/templates/AccountName/My Templates');

request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = "{ \\
  'applies_to': 'SmartVault.Accounting.TaxEngagement', \\
  'api_name': 'TaxEngagementCustomTemplate', \\
  'label': 'My Custom Tax Engagement Template', \\
  'groups': [ \\
    { \\
      'moniker': 'FirmManager', \\
      'name': 'Firm Managers', \\
      'rid': 8, \\
      'create': true \\
    }, \\
    { \\
      'moniker': 'AccountEmployees', \\
      'name': 'Employees', \\
      'rid': 18, \\
      'create': false \\
    } \\
  ], \\
  'folders': [ \\
    { \\
      'name': 'Tax Year 2012', \\
      'acl': { \\
        'aces': [ \\
          { \\
            'principal': 'AccountEmployees', \\
            'permissions': 15 \\
          }, \\
          { \\
            'principal': 'FirmManager', \\
            'permissions': 111 \\
          } \\
        ] \\
      }, \\
      'id': 'root', \\
      'folders': [ \\
        { \\
          'name': 'Client Tax Returns', \\
          'acl': { \\
            'aces': [ \\
              { \\
                'principal': 'FirmEmployees', \\
                'permissions': 15 \\
              } \\
            ] \\
          }, \\
          'id': 'client_tax_returns', \\
          'folder_association': { \\
            'tags': [ \\
              { \\
                'value': 'clientcopy.taxreturn.tags.accounting.smartvault.com' \\
              } \\
            ] \\
          } \\
        } \\
      ] \\
    } \\
  ] \\
}";

request.send(body);`;
export const createTemplateNode = `var request = require('request');

request({
  method: 'PUT',
  url: 'https://rest.smartvault.com/nodes/templates/AccountName/My Templates',
  headers: {
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  },
  body: "{  \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",  \\"api_name\\": \\"TaxEngagementCustomTemplate\\",  \\"label\\": \\"My Custom Tax Engagement Template\\",  \\"groups\\": [    {      \\"moniker\\": \\"FirmManager\\",      \\"name\\": \\"Firm Managers\\",      \\"rid\\": 8,      \\"create\\": true    },    {      \\"moniker\\": \\"AccountEmployees\\",      \\"name\\": \\"Employees\\",      \\"rid\\": 18,      \\"create\\": false    }  ],  \\"folders\\": [    {      \\"name\\": \\"Tax Year 2012\\",      \\"acl\\": {        \\"aces\\": [          {            \\"principal\\": \\"AccountEmployees\\",            \\"permissions\\": 15          },          {            \\"principal\\": \\"FirmManager\\",            \\"permissions\\": 111          }        ]      },      \\"id\\": \\"root\\",      \\"folders\\": [        {          \\"name\\": \\"Client Tax Returns\\",          \\"acl\\": {            \\"aces\\": [              {                \\"principal\\": \\"FirmEmployees\\",                \\"permissions\\": 15              }            ]          },          \\"id\\": \\"client_tax_returns\\",          \\"folder_association\\": {            \\"tags\\": [              {                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"              }            ]          }        }      ]    }  ]}"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const createTemplatePerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = {  &#34;applies_to&#34;: &#34;SmartVault.Accounting.TaxEngagement&#34;,  &#34;api_name&#34;: &#34;TaxEngagementCustomTemplate&#34;,  &#34;label&#34;: &#34;My Custom Tax Engagement Template&#34;,  &#34;groups&#34;: [    {      &#34;moniker&#34;: &#34;FirmManager&#34;,      &#34;name&#34;: &#34;Firm Managers&#34;,      &#34;rid&#34;: 8,      &#34;create&#34;: true    },    {      &#34;moniker&#34;: &#34;AccountEmployees&#34;,      &#34;name&#34;: &#34;Employees&#34;,      &#34;rid&#34;: 18,      &#34;create&#34;: false    }  ],  &#34;folders&#34;: [    {      &#34;name&#34;: &#34;Tax Year 2012&#34;,      &#34;acl&#34;: {        &#34;aces&#34;: [          {            &#34;principal&#34;: &#34;AccountEmployees&#34;,            &#34;permissions&#34;: 15          },          {            &#34;principal&#34;: &#34;FirmManager&#34;,            &#34;permissions&#34;: 111          }        ]      },      &#34;id&#34;: &#34;root&#34;,      &#34;folders&#34;: [        {          &#34;name&#34;: &#34;Client Tax Returns&#34;,          &#34;acl&#34;: {            &#34;aces&#34;: [              {                &#34;principal&#34;: &#34;FirmEmployees&#34;,                &#34;permissions&#34;: 15              }            ]          },          &#34;id&#34;: &#34;client_tax_returns&#34;,          &#34;folder_association&#34;: {            &#34;tags&#34;: [              {                &#34;value&#34;: &#34;clientcopy.taxreturn.tags.accounting.smartvault.com&#34;              }            ]          }        }      ]    }  ]};

$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->put("https://rest.smartvault.com/nodes/templates/AccountName/My Templates", Content => $data);

print $response->as_string;`;
export const createTemplatePython = `from urllib2 import Request, urlopen

values = """
  {
    "applies_to": "SmartVault.Accounting.TaxEngagement",
    "api_name": "TaxEngagementCustomTemplate",
    "label": "My Custom Tax Engagement Template",
    "groups": [
      {
        "moniker": "FirmManager",
        "name": "Firm Managers",
        "rid": 8,
        "create": true
      },
      {
        "moniker": "AccountEmployees",
        "name": "Employees",
        "rid": 18,
        "create": false
      }
    ],
    "folders": [
      {
        "name": "Tax Year 2012",
        "acl": {
          "aces": [
            {
              "principal": "AccountEmployees",
              "permissions": 15
            },
            {
              "principal": "FirmManager",
              "permissions": 111
            }
          ]
        },
        "id": "root",
        "folders": [
          {
            "name": "Client Tax Returns",
            "acl": {
              "aces": [
                {
                  "principal": "FirmEmployees",
                  "permissions": 15
                }
              ]
            },
            "id": "client_tax_returns",
            "folder_association": {
              "tags": [
                {
                  "value": "clientcopy.taxreturn.tags.accounting.smartvault.com"
                }
              ]
            }
          }
        ]
      }
    ]
  }
"""

headers = {
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/templates/AccountName/My Templates', data=values, headers=headers)
request.get_method = lambda: 'PUT'

response_body = urlopen(request).read()
print response_body`;
export const createTemplatePhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/templates/AccountName/My Templates");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "PUT");

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",
  \\"api_name\\": \\"TaxEngagementCustomTemplate\\",
  \\"label\\": \\"My Custom Tax Engagement Template\\",
  \\"groups\\": [
    {
      \\"moniker\\": \\"FirmManager\\",
      \\"name\\": \\"Firm Managers\\",
      \\"rid\\": 8,
      \\"create\\": true
    },
    {
      \\"moniker\\": \\"AccountEmployees\\",
      \\"name\\": \\"Employees\\",
      \\"rid\\": 18,
      \\"create\\": false
    }
  ],
  \\"folders\\": [
    {
      \\"name\\": \\"Tax Year 2012\\",
      \\"acl\\": {
        \\"aces\\": [
          {
            \\"principal\\": \\"AccountEmployees\\",
            \\"permissions\\": 15
          },
          {
            \\"principal\\": \\"FirmManager\\",
            \\"permissions\\": 111
          }
        ]
      },
      \\"id\\": \\"root\\",
      \\"folders\\": [
        {
          \\"name\\": \\"Client Tax Returns\\",
          \\"acl\\": {
            \\"aces\\": [
              {
                \\"principal\\": \\"FirmEmployees\\",
                \\"permissions\\": 15
              }
            ]
          },
          \\"id\\": \\"client_tax_returns\\",
          \\"folder_association\\": {
            \\"tags\\": [
              {
                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"
              }
            ]
          }
        }
      ]
    }
  ]
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const createTemplateRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "applies_to": "SmartVault.Accounting.TaxEngagement",
  "api_name": "TaxEngagementCustomTemplate",
  "label": "My Custom Tax Engagement Template",
  "groups": [
    {
      "moniker": "FirmManager",
      "name": "Firm Managers",
      "rid": 8,
      "create": true
    },
    {
      "moniker": "AccountEmployees",
      "name": "Employees",
      "rid": 18,
      "create": false
    }
  ],
  "folders": [
    {
      "name": "Tax Year 2012",
      "acl": {
        "aces": [
          {
            "principal": "AccountEmployees",
            "permissions": 15
          },
          {
            "principal": "FirmManager",
            "permissions": 111
          }
        ]
      },
      "id": "root",
      "folders": [
        {
          "name": "Client Tax Returns",
          "acl": {
            "aces": [
              {
                "principal": "FirmEmployees",
                "permissions": 15
              }
            ]
          },
          "id": "client_tax_returns",
          "folder_association": {
            "tags": [
              {
                "value": "clientcopy.taxreturn.tags.accounting.smartvault.com"
              }
            ]
          }
        }
      ]
    }
  ]
}'

headers = {
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.put 'https://rest.smartvault.com/nodes/templates/AccountName/My Templates', values, headers
puts response`;
export const createTemplateGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",\\n  \\"api_name\\": \\"TaxEngagementCustomTemplate\\",\\n  \\"label\\": \\"My Custom Tax Engagement Template\\",\\n  \\"groups\\": [\\n    {\\n      \\"moniker\\": \\"FirmManager\\",\\n      \\"name\\": \\"Firm Managers\\",\\n      \\"rid\\": 8,\\n      \\"create\\": true\\n    },\\n    {\\n      \\"moniker\\": \\"AccountEmployees\\",\\n      \\"name\\": \\"Employees\\",\\n      \\"rid\\": 18,\\n      \\"create\\": false\\n    }\\n  ],\\n  \\"folders\\": [\\n    {\\n      \\"name\\": \\"Tax Year 2012\\",\\n      \\"acl\\": {\\n        \\"aces\\": [\\n          {\\n            \\"principal\\": \\"AccountEmployees\\",\\n            \\"permissions\\": 15\\n          },\\n          {\\n            \\"principal\\": \\"FirmManager\\",\\n            \\"permissions\\": 111\\n          }\\n        ]\\n      },\\n      \\"id\\": \\"root\\",\\n      \\"folders\\": [\\n        {\\n          \\"name\\": \\"Client Tax Returns\\",\\n          \\"acl\\": {\\n            \\"aces\\": [\\n              {\\n                \\"principal\\": \\"FirmEmployees\\",\\n                \\"permissions\\": 15\\n              }\\n            ]\\n          },\\n          \\"id\\": \\"client_tax_returns\\",\\n          \\"folder_association\\": {\\n            \\"tags\\": [\\n              {\\n                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"\\n              }\\n            ]\\n          }\\n        }\\n      ]\\n    }\\n  ]\\n}")

\treq, _ := http.NewRequest("PUT", "https://rest.smartvault.com/nodes/templates/AccountName/My Templates", bytes.NewBuffer(body))

\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const createTemplateC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",  \\"api_name\\": \\"TaxEngagementCustomTemplate\\",  \\"label\\": \\"My Custom Tax Engagement Template\\",  \\"groups\\": [    {      \\"moniker\\": \\"FirmManager\\",      \\"name\\": \\"Firm Managers\\",      \\"rid\\": 8,      \\"create\\": true    },    {      \\"moniker\\": \\"AccountEmployees\\",      \\"name\\": \\"Employees\\",      \\"rid\\": 18,      \\"create\\": false    }  ],  \\"folders\\": [    {      \\"name\\": \\"Tax Year 2012\\",      \\"acl\\": {        \\"aces\\": [          {            \\"principal\\": \\"AccountEmployees\\",            \\"permissions\\": 15          },          {            \\"principal\\": \\"FirmManager\\",            \\"permissions\\": 111          }        ]      },      \\"id\\": \\"root\\",      \\"folders\\": [        {          \\"name\\": \\"Client Tax Returns\\",          \\"acl\\": {            \\"aces\\": [              {                \\"principal\\": \\"FirmEmployees\\",                \\"permissions\\": 15              }            ]          },          \\"id\\": \\"client_tax_returns\\",          \\"folder_association\\": {            \\"tags\\": [              {                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"              }            ]          }        }      ]    }  ]}"))
    {
      using (var response = await httpClient.PutAsync("nodes/templates/{AccountName}/My Templates", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const createTemplateVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/templates/AccountName/My Templates"), System.Net.HttpWebRequest)

request.Method = "PUT"

request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""applies_to\\"": \\""SmartVault.Accounting.TaxEngagement\\"",
  \\""api_name\\"": \\""TaxEngagementCustomTemplate\\"",
  \\""label\\"": \\""My Custom Tax Engagement Template\\"",
  \\""groups\\"": [
    {
      \\""moniker\\"": \\""FirmManager\\"",
      \\""name\\"": \\""Firm Managers\\"",
      \\""rid\\"": 8,
      \\""create\\"": true
    },
    {
      \\""moniker\\"": \\""AccountEmployees\\"",
      \\""name\\"": \\""Employees\\"",
      \\""rid\\"": 18,
      \\""create\\"": false
    }
  ],
  \\""folders\\"": [
    {
      \\""name\\"": \\""Tax Year 2012\\"",
      \\""acl\\"": {
        \\""aces\\"": [
          {
            \\""principal\\"": \\""AccountEmployees\\"",
            \\""permissions\\"": 15
          },
          {
            \\""principal\\"": \\""FirmManager\\"",
            \\""permissions\\"": 111
          }
        ]
      },
      \\""id\\"": \\""root\\"",
      \\""folders\\"": [
        {
          \\""name\\"": \\""Client Tax Returns\\"",
          \\""acl\\"": {
            \\""aces\\"": [
              {
                \\""principal\\"": \\""FirmEmployees\\"",
                \\""permissions\\"": 15
              }
            ]
          },
          \\""id\\"": \\""client_tax_returns\\"",
          \\""folder_association\\"": {
            \\""tags\\"": [
              {
                \\""value\\"": \\""clientcopy.taxreturn.tags.accounting.smartvault.com\\""
              }
            ]
          }
        }
      ]
    }
  ]
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const createTemplateGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "applies_to": "SmartVault.Accounting.TaxEngagement",
  "api_name": "TaxEngagementCustomTemplate",
  "label": "My Custom Tax Engagement Template",
  "groups": [
    {
      "moniker": "FirmManager",
      "name": "Firm Managers",
      "rid": 8,
      "create": true
    },
    {
      "moniker": "AccountEmployees",
      "name": "Employees",
      "rid": 18,
      "create": false
    }
  ],
  "folders": [
    {
      "name": "Tax Year 2012",
      "acl": {
        "aces": [
          {
            "principal": "AccountEmployees",
            "permissions": 15
          },
          {
            "principal": "FirmManager",
            "permissions": 111
          }
        ]
      },
      "id": "root",
      "folders": [
        {
          "name": "Client Tax Returns",
          "acl": {
            "aces": [
              {
                "principal": "FirmEmployees",
                "permissions": 15
              }
            ]
          },
          "id": "client_tax_returns",
          "folder_association": {
            "tags": [
              {
                "value": "clientcopy.taxreturn.tags.accounting.smartvault.com"
              }
            ]
          }
        }
      ]
    }
  ]
}')
response = client.put( path : "/nodes/templates/{AccountName}/My Templates",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : ANY )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const createTemplateObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/templates/AccountName/My Templates"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"PUT"];

[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",\\n  \\"api_name\\": \\"TaxEngagementCustomTemplate\\",\\n  \\"label\\": \\"My Custom Tax Engagement Template\\",\\n  \\"groups\\": [\\n    {\\n      \\"moniker\\": \\"FirmManager\\",\\n      \\"name\\": \\"Firm Managers\\",\\n      \\"rid\\": 8,\\n      \\"create\\": true\\n    },\\n    {\\n      \\"moniker\\": \\"AccountEmployees\\",\\n      \\"name\\": \\"Employees\\",\\n      \\"rid\\": 18,\\n      \\"create\\": false\\n    }\\n  ],\\n  \\"folders\\": [\\n    {\\n      \\"name\\": \\"Tax Year 2012\\",\\n      \\"acl\\": {\\n        \\"aces\\": [\\n          {\\n            \\"principal\\": \\"AccountEmployees\\",\\n            \\"permissions\\": 15\\n          },\\n          {\\n            \\"principal\\": \\"FirmManager\\",\\n            \\"permissions\\": 111\\n          }\\n        ]\\n      },\\n      \\"id\\": \\"root\\",\\n      \\"folders\\": [\\n        {\\n          \\"name\\": \\"Client Tax Returns\\",\\n          \\"acl\\": {\\n            \\"aces\\": [\\n              {\\n                \\"principal\\": \\"FirmEmployees\\",\\n                \\"permissions\\": 15\\n              }\\n            ]\\n          },\\n          \\"id\\": \\"client_tax_returns\\",\\n          \\"folder_association\\": {\\n            \\"tags\\": [\\n              {\\n                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"\\n              }\\n            ]\\n          }\\n        }\\n      ]\\n    }\\n  ]\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const createTemplateSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/templates/AccountName/My Templates")!
var request = URLRequest(url: url)
request.httpMethod = "PUT"
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"applies_to\\": \\"SmartVault.Accounting.TaxEngagement\\",\\n  \\"api_name\\": \\"TaxEngagementCustomTemplate\\",\\n  \\"label\\": \\"My Custom Tax Engagement Template\\",\\n  \\"groups\\": [\\n    {\\n      \\"moniker\\": \\"FirmManager\\",\\n      \\"name\\": \\"Firm Managers\\",\\n      \\"rid\\": 8,\\n      \\"create\\": true\\n    },\\n    {\\n      \\"moniker\\": \\"AccountEmployees\\",\\n      \\"name\\": \\"Employees\\",\\n      \\"rid\\": 18,\\n      \\"create\\": false\\n    }\\n  ],\\n  \\"folders\\": [\\n    {\\n      \\"name\\": \\"Tax Year 2012\\",\\n      \\"acl\\": {\\n        \\"aces\\": [\\n          {\\n            \\"principal\\": \\"AccountEmployees\\",\\n            \\"permissions\\": 15\\n          },\\n          {\\n            \\"principal\\": \\"FirmManager\\",\\n            \\"permissions\\": 111\\n          }\\n        ]\\n      },\\n      \\"id\\": \\"root\\",\\n      \\"folders\\": [\\n        {\\n          \\"name\\": \\"Client Tax Returns\\",\\n          \\"acl\\": {\\n            \\"aces\\": [\\n              {\\n                \\"principal\\": \\"FirmEmployees\\",\\n                \\"permissions\\": 15\\n              }\\n            ]\\n          },\\n          \\"id\\": \\"client_tax_returns\\",\\n          \\"folder_association\\": {\\n            \\"tags\\": [\\n              {\\n                \\"value\\": \\"clientcopy.taxreturn.tags.accounting.smartvault.com\\"\\n              }\\n            ]\\n          }\\n        }\\n      ]\\n    }\\n  ]\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// UPDATE TEMPLATE
export const updateTemplateRaw = `Headers:

Content-Type:application/json
Authorization:Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==
Accept:application/json

Body:

{
  "update": {
    "update_api_name": "true",
    "api_name": "SmartVault.Core.UserTemplate",
    "update_label": "true",
    "label": "SmartVault Employee",
    "update_plural_label": "true",
    "plural_label": "SmartVault Employees"
  },
  "copy": {
    "dst_uri": "/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate"
  },
  "change": {
    "version": 5,
    "comment": "Move Correspondence folder to My Folder",
    "changes": [
      {
        "selector": "/root/correspondence",
        "move": "/root/my_folder"
      },
      {
        "selector": "/root/my_folder",
        "merge": {
          "destination": "/root/my_folder/correspondence"
        }
      },
      {
        "selector": "/root",
        "add": {
          "id": "personal_folder",
          "name": "Personal Folder"
        }
      },
      {
        "selector": "/root/my_folder/correspondence",
        "delete": "true"
      },
      {
        "selector": "/root/my_folder",
        "folder_association": {
          "tags": {
            "add": {
              "tags": [
                {
                  "value": " misc.tags.core.smartvault.com "
                }
              ]
            }
          }
        }
      },
      {
        "selector": "/root/my_folder",
        "set_notifications": {
          "notifications": {
            "notifications": [
              {
                "principal": "AccountAdmins",
                "notifications": 2
              }
            ]
          }
        }
      }
    ]
  }
}`;
export const updateTemplateCurl = `curl --include \\
     --request POST \\
     --header "Content-Type: application/json" \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
     --data-binary "{
    \\"update\\": 
    {
        \\"update_api_name\\": \\"true\\",
        \\"api_name\\" : \\"SmartVault.Core.UserTemplate\\",
        \\"update_label\\" : \\"true\\",
        \\"label\\" : \\"SmartVault Employee\\",
        \\"update_plural_label\\" : \\"true\\",
        \\"plural_label\\" : \\"SmartVault Employees\\"
    },
    \\"copy\\":
    {
        \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"
    },
    \\"change\\": 
    {
        \\"version\\" : 5,
        \\"comment\\" : \\"Move Correspondence folder to My Folder\\",
        \\"changes\\" : 
        [
            {
                \\"selector\\" : \\"/root/correspondence\\",
                \\"move\\" : \\"/root/my_folder\\"
            },
            {
                \\"selector\\" : \\"/root/my_folder\\",
                \\"merge\\" : 
                {
                    \\"destination\\" : \\"/root/my_folder/correspondence\\"
                }
            },
            {
                \\"selector\\" : \\"/root\\",
                \\"add\\" : 
                {
                    \\"id\\": \\"personal_folder\\",
                    \\"name\\": \\"Personal Folder\\"
                }
            },
            {
                \\"selector\\" : \\"/root/my_folder/correspondence\\",
                \\"delete\\" : \\"true\\"
            },
            {
                \\"selector\\" : \\"/root/my_folder\\",
                \\"folder_association\\" : 
                {
                    \\"tags\\" : 
                    {
                        \\"add\\" : 
                        {
                            \\"tags\\" : 
                            [
                                {
                                    \\"value\\" : \\" misc.tags.core.smartvault.com \\"
                                }
                            ]
                        }
                    }
                }
            },
            {
                \\"selector\\" : \\"/root/my_folder\\",
                \\"set_notifications\\" : 
                {
                    \\"notifications\\" : 
                    {
                        \\"notifications\\" : 
                        [
                            {
                                \\"principal\\" : \\"AccountAdmins\\",
                                \\"notifications\\" : 2
                            }
                        ]
                    }
                }
            }
        ]
    }
}" \\
'https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition'`;
export const updateTemplateJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  \\"update\\": {    \\"update_api_name\\": \\"true\\",    \\"api_name\\": \\"SmartVault.Core.UserTemplate\\",    \\"update_label\\": \\"true\\",    \\"label\\": \\"SmartVault Employee\\",    \\"update_plural_label\\": \\"true\\",    \\"plural_label\\": \\"SmartVault Employees\\"  },  \\"copy\\": {    \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"  },  \\"change\\": {    \\"version\\": 5,    \\"comment\\": \\"Move Correspondence folder to My Folder\\",    \\"changes\\": [      {        \\"selector\\": \\"/root/correspondence\\",        \\"move\\": \\"/root/my_folder\\"      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"merge\\": {          \\"destination\\": \\"/root/my_folder/correspondence\\"        }      },      {        \\"selector\\": \\"/root\\",        \\"add\\": {          \\"id\\": \\"personal_folder\\",          \\"name\\": \\"Personal Folder\\"        }      },      {        \\"selector\\": \\"/root/my_folder/correspondence\\",        \\"delete\\": \\"true\\"      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"folder_association\\": {          \\"tags\\": {            \\"add\\": {              \\"tags\\": [                {                  \\"value\\": \\" misc.tags.core.smartvault.com \\"                }              ]            }          }        }      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"set_notifications\\": {          \\"notifications\\": {            \\"notifications\\": [              {                \\"principal\\": \\"AccountAdmins\\",                \\"notifications\\": 2              }            ]          }        }      }    ]  }}");
Response response = client.target("https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .post(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const updateTemplateJavascript = `var request = new XMLHttpRequest();

request.open('POST', 'https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {
  'update': {
    'update_api_name': 'true',
    'api_name': 'SmartVault.Core.UserTemplate',
    'update_label': 'true',
    'label': 'SmartVault Employee',
    'update_plural_label': 'true',
    'plural_label': 'SmartVault Employees'
  },
  'copy': {
    'dst_uri': '/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate'
  },
  'change': {
    'version': 5,
    'comment': 'Move Correspondence folder to My Folder',
    'changes': [
      {
        'selector': '/root/correspondence',
        'move': '/root/my_folder'
      },
      {
        'selector': '/root/my_folder',
        'merge': {
          'destination': '/root/my_folder/correspondence'
        }
      },
      {
        'selector': '/root',
        'add': {
          'id': 'personal_folder',
          'name': 'Personal Folder'
        }
      },
      {
        'selector': '/root/my_folder/correspondence',
        'delete': 'true'
      },
      {
        'selector': '/root/my_folder',
        'folder_association': {
          'tags': {
            'add': {
              'tags': [
                {
                  'value': ' misc.tags.core.smartvault.com '
                }
              ]
            }
          }
        }
      },
      {
        'selector': '/root/my_folder',
        'set_notifications': {
          'notifications': {
            'notifications': [
              {
                'principal': 'AccountAdmins',
                'notifications': 2
              }
            ]
          }
        }
      }
    ]
  }
};

request.send(JSON.stringify(body));`;
export const updateTemplateNode = `var request = require('request');

request({
  method: 'POST',
  url: 'https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  },
  body: "{  \\"update\\": {    \\"update_api_name\\": \\"true\\",    \\"api_name\\": \\"SmartVault.Core.UserTemplate\\",    \\"update_label\\": \\"true\\",    \\"label\\": \\"SmartVault Employee\\",    \\"update_plural_label\\": \\"true\\",    \\"plural_label\\": \\"SmartVault Employees\\"  },  \\"copy\\": {    \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"  },  \\"change\\": {    \\"version\\": 5,    \\"comment\\": \\"Move Correspondence folder to My Folder\\",    \\"changes\\": [      {        \\"selector\\": \\"/root/correspondence\\",        \\"move\\": \\"/root/my_folder\\"      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"merge\\": {          \\"destination\\": \\"/root/my_folder/correspondence\\"        }      },      {        \\"selector\\": \\"/root\\",        \\"add\\": {          \\"id\\": \\"personal_folder\\",          \\"name\\": \\"Personal Folder\\"        }      },      {        \\"selector\\": \\"/root/my_folder/correspondence\\",        \\"delete\\": \\"true\\"      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"folder_association\\": {          \\"tags\\": {            \\"add\\": {              \\"tags\\": [                {                  \\"value\\": \\" misc.tags.core.smartvault.com \\"                }              ]            }          }        }      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"set_notifications\\": {          \\"notifications\\": {            \\"notifications\\": [              {                \\"principal\\": \\"AccountAdmins\\",                \\"notifications\\": 2              }            ]          }        }      }    ]  }}"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const updateTemplatePerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "update": {    "update_api_name": "true",    "api_name": "SmartVault.Core.UserTemplate",    "update_label": "true",    "label": "SmartVault Employee",    "update_plural_label": "true",    "plural_label": "SmartVault Employees"  },  "copy": {    "dst_uri": "/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate"  },  "change": {    "version": 5,    "comment": "Move Correspondence folder to My Folder",    "changes": [      {        "selector": "/root/correspondence",        "move": "/root/my_folder"      },      {        "selector": "/root/my_folder",        "merge": {          "destination": "/root/my_folder/correspondence"        }      },      {        "selector": "/root",        "add": {          "id": "personal_folder",          "name": "Personal Folder"        }      },      {        "selector": "/root/my_folder/correspondence",        "delete": "true"      },      {        "selector": "/root/my_folder",        "folder_association": {          "tags": {            "add": {              "tags": [                {                  "value": " misc.tags.core.smartvault.com "                }              ]            }          }        }      },      {        "selector": "/root/my_folder",        "set_notifications": {          "notifications": {            "notifications": [              {                "principal": "AccountAdmins",                "notifications": 2              }            ]          }        }      }    ]  }}';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition", Content => $data);

print $response->as_string;`;
export const updateTemplatePython = `from urllib2 import Request, urlopen

values = """
  {
    "update": {
      "update_api_name": "true",
      "api_name": "SmartVault.Core.UserTemplate",
      "update_label": "true",
      "label": "SmartVault Employee",
      "update_plural_label": "true",
      "plural_label": "SmartVault Employees"
    },
    "copy": {
      "dst_uri": "/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate"
    },
    "change": {
      "version": 5,
      "comment": "Move Correspondence folder to My Folder",
      "changes": [
        {
          "selector": "/root/correspondence",
          "move": "/root/my_folder"
        },
        {
          "selector": "/root/my_folder",
          "merge": {
            "destination": "/root/my_folder/correspondence"
          }
        },
        {
          "selector": "/root",
          "add": {
            "id": "personal_folder",
            "name": "Personal Folder"
          }
        },
        {
          "selector": "/root/my_folder/correspondence",
          "delete": "true"
        },
        {
          "selector": "/root/my_folder",
          "folder_association": {
            "tags": {
              "add": {
                "tags": [
                  {
                    "value": " misc.tags.core.smartvault.com "
                  }
                ]
              }
            }
          }
        },
        {
          "selector": "/root/my_folder",
          "set_notifications": {
            "notifications": {
              "notifications": [
                {
                  "principal": "AccountAdmins",
                  "notifications": 2
                }
              ]
            }
          }
        }
      ]
    }
  }
"""

headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition', data=values, headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const updateTemplatePhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_POST, TRUE);

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"update\\": {
    \\"update_api_name\\": \\"true\\",
    \\"api_name\\": \\"SmartVault.Core.UserTemplate\\",
    \\"update_label\\": \\"true\\",
    \\"label\\": \\"SmartVault Employee\\",
    \\"update_plural_label\\": \\"true\\",
    \\"plural_label\\": \\"SmartVault Employees\\"
  },
  \\"copy\\": {
    \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"
  },
  \\"change\\": {
    \\"version\\": 5,
    \\"comment\\": \\"Move Correspondence folder to My Folder\\",
    \\"changes\\": [
      {
        \\"selector\\": \\"/root/correspondence\\",
        \\"move\\": \\"/root/my_folder\\"
      },
      {
        \\"selector\\": \\"/root/my_folder\\",
        \\"merge\\": {
          \\"destination\\": \\"/root/my_folder/correspondence\\"
        }
      },
      {
        \\"selector\\": \\"/root\\",
        \\"add\\": {
          \\"id\\": \\"personal_folder\\",
          \\"name\\": \\"Personal Folder\\"
        }
      },
      {
        \\"selector\\": \\"/root/my_folder/correspondence\\",
        \\"delete\\": \\"true\\"
      },
      {
        \\"selector\\": \\"/root/my_folder\\",
        \\"folder_association\\": {
          \\"tags\\": {
            \\"add\\": {
              \\"tags\\": [
                {
                  \\"value\\": \\" misc.tags.core.smartvault.com \\"
                }
              ]
            }
          }
        }
      },
      {
        \\"selector\\": \\"/root/my_folder\\",
        \\"set_notifications\\": {
          \\"notifications\\": {
            \\"notifications\\": [
              {
                \\"principal\\": \\"AccountAdmins\\",
                \\"notifications\\": 2
              }
            ]
          }
        }
      }
    ]
  }
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const updateTemplateRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "update": {
    "update_api_name": "true",
    "api_name": "SmartVault.Core.UserTemplate",
    "update_label": "true",
    "label": "SmartVault Employee",
    "update_plural_label": "true",
    "plural_label": "SmartVault Employees"
  },
  "copy": {
    "dst_uri": "/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate"
  },
  "change": {
    "version": 5,
    "comment": "Move Correspondence folder to My Folder",
    "changes": [
      {
        "selector": "/root/correspondence",
        "move": "/root/my_folder"
      },
      {
        "selector": "/root/my_folder",
        "merge": {
          "destination": "/root/my_folder/correspondence"
        }
      },
      {
        "selector": "/root",
        "add": {
          "id": "personal_folder",
          "name": "Personal Folder"
        }
      },
      {
        "selector": "/root/my_folder/correspondence",
        "delete": "true"
      },
      {
        "selector": "/root/my_folder",
        "folder_association": {
          "tags": {
            "add": {
              "tags": [
                {
                  "value": " misc.tags.core.smartvault.com "
                }
              ]
            }
          }
        }
      },
      {
        "selector": "/root/my_folder",
        "set_notifications": {
          "notifications": {
            "notifications": [
              {
                "principal": "AccountAdmins",
                "notifications": 2
              }
            ]
          }
        }
      }
    ]
  }
}'

headers = {
  :content_type => 'application/json',
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.post 'https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition', values, headers
puts response`;
export const updateTemplateGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"update\\": {\\n    \\"update_api_name\\": \\"true\\",\\n    \\"api_name\\": \\"SmartVault.Core.UserTemplate\\",\\n    \\"update_label\\": \\"true\\",\\n    \\"label\\": \\"SmartVault Employee\\",\\n    \\"update_plural_label\\": \\"true\\",\\n    \\"plural_label\\": \\"SmartVault Employees\\"\\n  },\\n  \\"copy\\": {\\n    \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"\\n  },\\n  \\"change\\": {\\n    \\"version\\": 5,\\n    \\"comment\\": \\"Move Correspondence folder to My Folder\\",\\n    \\"changes\\": [\\n      {\\n        \\"selector\\": \\"/root/correspondence\\",\\n        \\"move\\": \\"/root/my_folder\\"\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"merge\\": {\\n          \\"destination\\": \\"/root/my_folder/correspondence\\"\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root\\",\\n        \\"add\\": {\\n          \\"id\\": \\"personal_folder\\",\\n          \\"name\\": \\"Personal Folder\\"\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder/correspondence\\",\\n        \\"delete\\": \\"true\\"\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"folder_association\\": {\\n          \\"tags\\": {\\n            \\"add\\": {\\n              \\"tags\\": [\\n                {\\n                  \\"value\\": \\" misc.tags.core.smartvault.com \\"\\n                }\\n              ]\\n            }\\n          }\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"set_notifications\\": {\\n          \\"notifications\\": {\\n            \\"notifications\\": [\\n              {\\n                \\"principal\\": \\"AccountAdmins\\",\\n                \\"notifications\\": 2\\n              }\\n            ]\\n          }\\n        }\\n      }\\n    ]\\n  }\\n}")

\treq, _ := http.NewRequest("POST", "https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition", bytes.NewBuffer(body))

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const updateTemplateC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"update\\": {    \\"update_api_name\\": \\"true\\",    \\"api_name\\": \\"SmartVault.Core.UserTemplate\\",    \\"update_label\\": \\"true\\",    \\"label\\": \\"SmartVault Employee\\",    \\"update_plural_label\\": \\"true\\",    \\"plural_label\\": \\"SmartVault Employees\\"  },  \\"copy\\": {    \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"  },  \\"change\\": {    \\"version\\": 5,    \\"comment\\": \\"Move Correspondence folder to My Folder\\",    \\"changes\\": [      {        \\"selector\\": \\"/root/correspondence\\",        \\"move\\": \\"/root/my_folder\\"      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"merge\\": {          \\"destination\\": \\"/root/my_folder/correspondence\\"        }      },      {        \\"selector\\": \\"/root\\",        \\"add\\": {          \\"id\\": \\"personal_folder\\",          \\"name\\": \\"Personal Folder\\"        }      },      {        \\"selector\\": \\"/root/my_folder/correspondence\\",        \\"delete\\": \\"true\\"      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"folder_association\\": {          \\"tags\\": {            \\"add\\": {              \\"tags\\": [                {                  \\"value\\": \\" misc.tags.core.smartvault.com \\"                }              ]            }          }        }      },      {        \\"selector\\": \\"/root/my_folder\\",        \\"set_notifications\\": {          \\"notifications\\": {            \\"notifications\\": [              {                \\"principal\\": \\"AccountAdmins\\",                \\"notifications\\": 2              }            ]          }        }      }    ]  }}", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PostAsync("nodes/templates/{AccountName}/My Templates/{EntityDefinition}", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const updateTemplateVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition"), System.Net.HttpWebRequest)

request.Method = "POST"

request.ContentType = "application/json"
request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""update\\"": {
    \\""update_api_name\\"": \\""true\\"",
    \\""api_name\\"": \\""SmartVault.Core.UserTemplate\\"",
    \\""update_label\\"": \\""true\\"",
    \\""label\\"": \\""SmartVault Employee\\"",
    \\""update_plural_label\\"": \\""true\\"",
    \\""plural_label\\"": \\""SmartVault Employees\\""
  },
  \\""copy\\"": {
    \\""dst_uri\\"": \\""/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\""
  },
  \\""change\\"": {
    \\""version\\"": 5,
    \\""comment\\"": \\""Move Correspondence folder to My Folder\\"",
    \\""changes\\"": [
      {
        \\""selector\\"": \\""/root/correspondence\\"",
        \\""move\\"": \\""/root/my_folder\\""
      },
      {
        \\""selector\\"": \\""/root/my_folder\\"",
        \\""merge\\"": {
          \\""destination\\"": \\""/root/my_folder/correspondence\\""
        }
      },
      {
        \\""selector\\"": \\""/root\\"",
        \\""add\\"": {
          \\""id\\"": \\""personal_folder\\"",
          \\""name\\"": \\""Personal Folder\\""
        }
      },
      {
        \\""selector\\"": \\""/root/my_folder/correspondence\\"",
        \\""delete\\"": \\""true\\""
      },
      {
        \\""selector\\"": \\""/root/my_folder\\"",
        \\""folder_association\\"": {
          \\""tags\\"": {
            \\""add\\"": {
              \\""tags\\"": [
                {
                  \\""value\\"": \\"" misc.tags.core.smartvault.com \\""
                }
              ]
            }
          }
        }
      },
      {
        \\""selector\\"": \\""/root/my_folder\\"",
        \\""set_notifications\\"": {
          \\""notifications\\"": {
            \\""notifications\\"": [
              {
                \\""principal\\"": \\""AccountAdmins\\"",
                \\""notifications\\"": 2
              }
            ]
          }
        }
      }
    ]
  }
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const updateTemplateGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "update": {
    "update_api_name": "true",
    "api_name": "SmartVault.Core.UserTemplate",
    "update_label": "true",
    "label": "SmartVault Employee",
    "update_plural_label": "true",
    "plural_label": "SmartVault Employees"
  },
  "copy": {
    "dst_uri": "/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate"
  },
  "change": {
    "version": 5,
    "comment": "Move Correspondence folder to My Folder",
    "changes": [
      {
        "selector": "/root/correspondence",
        "move": "/root/my_folder"
      },
      {
        "selector": "/root/my_folder",
        "merge": {
          "destination": "/root/my_folder/correspondence"
        }
      },
      {
        "selector": "/root",
        "add": {
          "id": "personal_folder",
          "name": "Personal Folder"
        }
      },
      {
        "selector": "/root/my_folder/correspondence",
        "delete": "true"
      },
      {
        "selector": "/root/my_folder",
        "folder_association": {
          "tags": {
            "add": {
              "tags": [
                {
                  "value": " misc.tags.core.smartvault.com "
                }
              ]
            }
          }
        }
      },
      {
        "selector": "/root/my_folder",
        "set_notifications": {
          "notifications": {
            "notifications": [
              {
                "principal": "AccountAdmins",
                "notifications": 2
              }
            ]
          }
        }
      }
    ]
  }
}')
response = client.post( path : "/nodes/templates/{AccountName}/My Templates/{EntityDefinition}",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const updateTemplateObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"POST"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"update\\": {\\n    \\"update_api_name\\": \\"true\\",\\n    \\"api_name\\": \\"SmartVault.Core.UserTemplate\\",\\n    \\"update_label\\": \\"true\\",\\n    \\"label\\": \\"SmartVault Employee\\",\\n    \\"update_plural_label\\": \\"true\\",\\n    \\"plural_label\\": \\"SmartVault Employees\\"\\n  },\\n  \\"copy\\": {\\n    \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"\\n  },\\n  \\"change\\": {\\n    \\"version\\": 5,\\n    \\"comment\\": \\"Move Correspondence folder to My Folder\\",\\n    \\"changes\\": [\\n      {\\n        \\"selector\\": \\"/root/correspondence\\",\\n        \\"move\\": \\"/root/my_folder\\"\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"merge\\": {\\n          \\"destination\\": \\"/root/my_folder/correspondence\\"\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root\\",\\n        \\"add\\": {\\n          \\"id\\": \\"personal_folder\\",\\n          \\"name\\": \\"Personal Folder\\"\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder/correspondence\\",\\n        \\"delete\\": \\"true\\"\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"folder_association\\": {\\n          \\"tags\\": {\\n            \\"add\\": {\\n              \\"tags\\": [\\n                {\\n                  \\"value\\": \\" misc.tags.core.smartvault.com \\"\\n                }\\n              ]\\n            }\\n          }\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"set_notifications\\": {\\n          \\"notifications\\": {\\n            \\"notifications\\": [\\n              {\\n                \\"principal\\": \\"AccountAdmins\\",\\n                \\"notifications\\": 2\\n              }\\n            ]\\n          }\\n        }\\n      }\\n    ]\\n  }\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const updateTemplateSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/templates/AccountName/My Templates/EntityDefinition")!
var request = URLRequest(url: url)
request.httpMethod = "POST"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"update\\": {\\n    \\"update_api_name\\": \\"true\\",\\n    \\"api_name\\": \\"SmartVault.Core.UserTemplate\\",\\n    \\"update_label\\": \\"true\\",\\n    \\"label\\": \\"SmartVault Employee\\",\\n    \\"update_plural_label\\": \\"true\\",\\n    \\"plural_label\\": \\"SmartVault Employees\\"\\n  },\\n  \\"copy\\": {\\n    \\"dst_uri\\": \\"/nodes/template/SmartVault/My Templates/Intuit.Accounting.DMS.TaxTemplate\\"\\n  },\\n  \\"change\\": {\\n    \\"version\\": 5,\\n    \\"comment\\": \\"Move Correspondence folder to My Folder\\",\\n    \\"changes\\": [\\n      {\\n        \\"selector\\": \\"/root/correspondence\\",\\n        \\"move\\": \\"/root/my_folder\\"\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"merge\\": {\\n          \\"destination\\": \\"/root/my_folder/correspondence\\"\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root\\",\\n        \\"add\\": {\\n          \\"id\\": \\"personal_folder\\",\\n          \\"name\\": \\"Personal Folder\\"\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder/correspondence\\",\\n        \\"delete\\": \\"true\\"\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"folder_association\\": {\\n          \\"tags\\": {\\n            \\"add\\": {\\n              \\"tags\\": [\\n                {\\n                  \\"value\\": \\" misc.tags.core.smartvault.com \\"\\n                }\\n              ]\\n            }\\n          }\\n        }\\n      },\\n      {\\n        \\"selector\\": \\"/root/my_folder\\",\\n        \\"set_notifications\\": {\\n          \\"notifications\\": {\\n            \\"notifications\\": [\\n              {\\n                \\"principal\\": \\"AccountAdmins\\",\\n                \\"notifications\\": 2\\n              }\\n            ]\\n          }\\n        }\\n      }\\n    ]\\n  }\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;
