import React, { FC } from 'react';
import Container from './styles';
import { Props } from './types';

const Row: FC<Props> = ({ codeExamples, children, objectRow, ...rest }) => {
	return (
		<Container codeExamples={codeExamples} $objectRow={objectRow} {...rest}>
			{children}
		</Container>
	);
};

export default Row;
