import React, { FC } from 'react';
import { Container, Title, Name, Type, Description, Values, Content } from './styles';
import Props from './types';

const Attributes: FC<Props> = ({ items, ...rest }) => {
	return (
		<Container {...rest}>
			{items.map(({ name, type, description, values }) => {
				return (
					<Content key={`${name}_${type}_${description}`}>
						<Title>
							<Name>
								<code>{name}</code>
							</Name>
							<Type>{type}</Type>
						</Title>
						<Description>
							{description}
							{values && (
								<Values>
									Values can be{' '}
									{values.map((value, index, { length }) => {
										const separator = index + 1 === length ? ' or ' : ', ';
										return (
											<span key={value}>
												{index && index !== values.length ? separator : ''} {`"${value}"`}
											</span>
										);
									})}
								</Values>
							)}
						</Description>
					</Content>
				);
			})}
		</Container>
	);
};

export default Attributes;
