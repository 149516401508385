import React, { FC, useState } from 'react';
import {
	Wrapper,
	Container,
	Content,
	Check,
	Copy,
	Header,
	Title,
	Row,
	Link,
	Key,
	Value,
	CopyToClipboardContainer,
} from './styles';
import Props from './types';
import CopyToClipboard from 'react-copy-to-clipboard';

const Table: FC<Props> = ({ title, rows, data, ...rest }) => {
	const [copied, setCopied] = useState('');
	return (
		<Wrapper {...rest}>
			<Container>
				<Header>
					<Title>{title}</Title>
				</Header>
				<Content>
					{rows.map(({ param, subtitle, value, link }) => {
						const endpointsTable =
							param.includes('GET') ||
							param.includes('PUT') ||
							param.includes('POST') ||
							param.includes('DELETE');
						const key = `${param}_${value}_${link}`;
						return link ? (
							<Row key={key} data={data} data-title={subtitle ? subtitle : null}>
								<Link href={link}>
									<Key data={data} param={param} total={rows.length}>
										{param}
									</Key>
									{value && <Value>{value}</Value>}
								</Link>
								{endpointsTable && (
									<>
										{copied === key ? (
											<Check />
										) : (
											<CopyToClipboardContainer title="Copy content to clipboard">
												<CopyToClipboard
													text={value}
													onCopy={() => {
														setCopied(key);
														setTimeout(() => setCopied(''), 1500);
													}}
												>
													<Copy />
												</CopyToClipboard>
											</CopyToClipboardContainer>
										)}
									</>
								)}
							</Row>
						) : (
							<Row key={key} data={data}>
								<Key data={data} param={param} total={rows.length}>
									{param}
								</Key>
								{value && <Value>{value}</Value>}
								{endpointsTable && (
									<>
										{copied === key ? (
											<Check />
										) : (
											<CopyToClipboardContainer title="Copy content to clipboard">
												<CopyToClipboard
													text={value}
													onCopy={() => {
														setCopied(key);
														setTimeout(() => setCopied(''), 1500);
													}}
												>
													<Copy />
												</CopyToClipboard>
											</CopyToClipboardContainer>
										)}
									</>
								)}
							</Row>
						);
					})}
				</Content>
			</Container>
		</Wrapper>
	);
};

export default Table;
