import styled, { css } from 'styled-components';
import {
	Tabs as DefaultTabs,
	Tab as DefaultTab,
	TabPanel as DefaultTabPanel,
	TabList as DefaultTabList,
} from 'react-web-tabs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon, CheckIcon } from '../Icons';

export const Container = styled(DefaultTabs)``;

export const TabList = styled(DefaultTabList)`
	border-bottom: 0.0625rem solid var(--theme-ui-colors-border, #2d3747);
`;

export const Tab = styled(DefaultTab)`
	background: none;
	border: none;
	border-radius: 0.5rem 0.5rem 0 0;
	color: white;
	cursor: pointer;
	padding: 1rem 1.5rem;
	&[aria-selected='true'] {
		background-color: rgba(0, 0, 0, 0.25);
		color: rgba(255, 255, 255, 0.75);
		font-weight: 700;
		outline: 0;
		position: relative;
		::after {
			content: '';
			position: absolute;
			bottom: -0.0625rem;
			left: 0;
			width: 100%;
			border-bottom: 0.1875rem solid #00bf99;
		}
	}
	:focus {
		background-color: rgba(0, 0, 0, 0.25);
		outline: 0;
	}
	&[aria-selected='false'] {
		:hover {
			background-color: rgba(0, 0, 0, 0.1);
			color: rgba(255, 255, 255, 0.75);
		}
	}
`;

export const Copy = styled(CopyIcon)``;

const commonAbsoluteStyles = css`
	position: absolute;
	right: 1.5rem;
	top: 1rem;
`;

export const Check = styled(CheckIcon)`
	${commonAbsoluteStyles};
`;

export const CopyToClipboardButton = styled(CopyToClipboard)`
	${commonAbsoluteStyles};
	opacity: 0;
	transition: opacity 0.25s ease-in-out;
`;

export const TabPanel = styled(DefaultTabPanel)`
	position: relative;
	:hover {
		${CopyToClipboardButton} {
			opacity: 1;
		}
	}
	pre {
		margin-top: 1rem;
	}
`;
