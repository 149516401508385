import styled, { css } from 'styled-components';
import Check from '../Icons/Check';

export const Container = styled.div<{ inline?: boolean }>`
	display: flex;
	flex-direction: column;
	${({ inline }) =>
		inline &&
		css`
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: -1rem;
		`}
`;
export const ListRow = styled.div<{ inline?: boolean }>`
	align-items: flex-start;
	display: flex;
	padding-left: 0.5rem;
	${({ inline }) =>
		inline
			? css`
					flex-basis: 25%;
					margin-right: 1rem;
					margin-top: 1rem;
					@media (max-width: 767px) {
						flex-basis: 100%;
					}
			  `
			: css`
					&:not(:first-child) {
						margin-top: 1rem;
					}
			  `}
`;
export const Info = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
`;
export const Title = styled.span`
	white-space: break-spaces;
`;
export const Text = styled.span`
	font-size: 0.875rem;
	opacity: 0.5;
	white-space: break-spaces;
`;
export const CheckIcon = styled(Check)`
	height: 1.5rem;
	margin-right: 0.5rem;
`;
