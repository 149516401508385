import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
`;

export const Title = styled.p`
	color: rgba(0 0 0 0.6);
	font-weight: 600;
`;

export const Img = styled.img<{ height?: number; width?: number }>`
	border-radius: 0.25rem;
	box-shadow: rgba(0, 0, 0, 0.3) 0 0.0625rem 0.1875rem;
	height: auto;
	max-width: 59.375rem;
	object-fit: cover;
	width: 100%;

	${({ height, width }) =>
		(height &&
			!width &&
			css`
				height: ${height}rem;
				width: auto;
			`) ||
		(width &&
			!height &&
			css`
				height: auto;
				width: ${width}rem;
			`) ||
		(width &&
			height &&
			css`
				height: ${height}rem;
				width: ${width}rem;
			`)};
`;
