// GETTING GROUP INFO
export const getGroupsCurl = `curl --include \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/grp/{path}'`;
export const getGroupsJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/grp/{path}")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .get();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const getGroupsJavascript = `var request = new XMLHttpRequest();

request.open('GET', 'https://rest.smartvault.com/nodes/grp/{path}');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const getGroupsNode = `var request = require('request');

request({
  method: 'GET',
  url: 'https://rest.smartvault.com/nodes/grp/{path}',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const getGroupsPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->get("https://rest.smartvault.com/nodes/grp/{path}");

print $response->as_string;`;
export const getGroupsPython = `from urllib2 import Request, urlopen

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/grp/{path}', headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const getGroupsPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/grp/{path}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const getGroupsRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.get 'https://rest.smartvault.com/nodes/grp/{path}', headers
puts response`;
export const getGroupsGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("GET", "https://rest.smartvault.com/nodes/grp/{path}", nil)

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const getGroupsC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
  using(var response = await httpClient.GetAsync("nodes/grp/{path}{?children,acl,eprop,search,page,per_page,sort,direction}"))
  {
 
        string responseData = await response.Content.ReadAsStringAsync();
  }
}`;
export const getGroupsVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/grp/{path}"), System.Net.HttpWebRequest)

request.Method = "GET"

request.ContentType = "application/json"
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const getGroupsGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

response = client.get( path : "/nodes/grp/{path}{?children,acl,eprop,search,page,per_page,sort,direction}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const getGroupsObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/grp/{path}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"GET"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const getGroupsSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/grp/{path}")!
var request = URLRequest(url: url)
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// ADD MEMBERS TO GROUP
export const addMembersGroupCurl = `curl --include \\
     --request PUT \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}'`;
export const addMembersGroupJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("");
Response response = client.target("https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .put(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const addMembersGroupJavascript = `var request = new XMLHttpRequest();

request.open('PUT', 'https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const addMembersGroupNode = `var request = require('request');

request({
  method: 'PUT',
  url: 'https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const addMembersGroupPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->put("https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}");

print $response->as_string;`;
export const addMembersGroupPython = `from urllib2 import Request, urlopen

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}', headers=headers)
request.get_method = lambda: 'PUT'

response_body = urlopen(request).read()
print response_body`;
export const addMembersGroupPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "PUT");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const addMembersGroupRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.put 'https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}', headers
puts response`;
export const addMembersGroupGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("PUT", "https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}", nil)

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const addMembersGroupC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PutAsync("nodes/grp/{path}/groups/{groupType}/{userEmail}", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const addMembersGroupVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}"), System.Net.HttpWebRequest)

request.Method = "PUT"

request.ContentType = "application/json"
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const addMembersGroupGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText()
response = client.put( path : "/nodes/grp/{path}/groups/{groupType}/{userEmail}",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const addMembersGroupObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"PUT"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const addMembersGroupSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/grp/{path}/groups/{groupType}/{userEmail}")!
var request = URLRequest(url: url)
request.httpMethod = "PUT"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;
