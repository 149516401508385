// GET ENTITY DEFINITION
export const getEntityDefinitionsCurl = `curl --include \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/entity-definition/{path}'`;
export const getEntityDefinitionsJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/entity-definition/{path}")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .get();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const getEntityDefinitionsJavascript = `var request = new XMLHttpRequest();

request.open('GET', 'https://rest.smartvault.com/nodes/entity-definition/{path}');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const getEntityDefinitionsNode = `var request = require('request');

request({
  method: 'GET',
  url: 'https://rest.smartvault.com/nodes/entity-definition/{path}',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const getEntityDefinitionsPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->get("https://rest.smartvault.com/nodes/entity-definition/{path}");

print $response->as_string;`;
export const getEntityDefinitionsPython = `from urllib2 import Request, urlopen

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/entity-definition/{path}', headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const getEntityDefinitionsPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/entity-definition/{path}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const getEntityDefinitionsRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.get 'https://rest.smartvault.com/nodes/entity-definition/{path}', headers
puts response`;
export const getEntityDefinitionsGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("GET", "https://rest.smartvault.com/nodes/entity-definition/{path}", nil)

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const getEntityDefinitionsC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
  using(var response = await httpClient.GetAsync("nodes/entity-definition/{path}"))
  {
 
        string responseData = await response.Content.ReadAsStringAsync();
  }
}`;
export const getEntityDefinitionsVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/entity-definition/{path}"), System.Net.HttpWebRequest)

request.Method = "GET"

request.ContentType = "application/json"
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const getEntityDefinitionsGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

response = client.get( path : "/nodes/entity-definition/{path}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const getEntityDefinitionsObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/entity-definition/{path}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"GET"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const getEntityDefinitionsSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/entity-definition/{path}")!
var request = URLRequest(url: url)
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// GET ENTITY
export const getEntityCurl = `curl --include \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/entity/{path}'`;
export const getEntityJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/entity/{path}")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .get();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const getEntityJavascript = `var request = new XMLHttpRequest();

request.open('GET', 'https://rest.smartvault.com/nodes/entity/{path}');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const getEntityNode = `var request = require('request');

request({
  method: 'GET',
  url: 'https://rest.smartvault.com/nodes/entity/{path}',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const getEntityPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->get("https://rest.smartvault.com/nodes/entity/{path}");

print $response->as_string;`;
export const getEntityPython = `from urllib2 import Request, urlopen

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/entity/{path}', headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const getEntityPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/entity/{path}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const getEntityRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.get 'https://rest.smartvault.com/nodes/entity/{path}', headers
puts response`;
export const getEntityGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("GET", "https://rest.smartvault.com/nodes/entity/{path}", nil)

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const getEntityC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
  using(var response = await httpClient.GetAsync("nodes/entity/{path}"))
  {
 
        string responseData = await response.Content.ReadAsStringAsync();
  }
}`;
export const getEntityVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/entity/{path}"), System.Net.HttpWebRequest)

request.Method = "GET"

request.ContentType = "application/json"
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const getEntityGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

response = client.get( path : "/nodes/entity/{path}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const getEntityObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/entity/{path}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"GET"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const getEntitySwift = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/entity/{path}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"GET"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;

// CREATE ENTITY
export const createEntityRaw = `Headers:

Content-Type:application/json
Authorization:Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==
Accept:application/json

Body:

{  
    "entity":{  
        "meta_data":{  
            "entity_definition":"SmartVault.Accounting.Client",
            "name":"Batman"
        },
        "smart_vault":{  
            "accounting":{  
                "client":{  
                "persons":[  
                    {  
                        "names":[  
                            {  
                            "FirstName":"Bruce",
                            "MiddleName":  ""                          
                            "LastName":"Wayne"
                            }
                        ]
                     }
                ],
                "type_qualifier":"Individual",
                "private":false
                }
            }
        }
     },
    "upsert":true,
}`;
export const createEntityCurl = `curl --include \\
     --request PUT \\
     --header "Content-Type: application/json" \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
     --data-binary "{  
    \\"entity\\":{  
        \\"meta_data\\":{  
            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",
            \\"name\\":\\"Batman\\"
        },
        \\"smart_vault\\":{  
            \\"accounting\\":{  
                \\"client\\":{  
                \\"persons\\":[  
                    {  
                        \\"names\\":[  
                            {  
                            \\"FirstName\\":\\"Bruce\\",
                            \\"MiddleName\\":  \\"\\"                          
                            \\"LastName\\":\\"Wayne\\"
                            }
                        ]
                     }
                ],
                \\"type_qualifier\\":\\"Individual\\",
                \\"private\\":false
                }
            }
        }
     },
    \\"upsert\\":true,
    }" \\
'https://rest.smartvault.com/nodes/entity/path'`;
export const createEntityJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  
    \\"entity\\":{  
        \\"meta_data\\":{  
            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",
            \\"name\\":\\"Batman\\"
        },
        \\"smart_vault\\":{  
            \\"accounting\\":{  
                \\"client\\":{  
                \\"persons\\":[  
                    {  
                        \\"names\\":[  
                            {  
                            \\"FirstName\\":\\"Bruce\\",
                            \\"MiddleName\\":  \\"\\"                          
                            \\"LastName\\":\\"Wayne\\"
                            }
                        ]
                     }
                ],
                \\"type_qualifier\\":\\"Individual\\",
                \\"private\\":false
                }
            }
        }
     },
    \\"upsert\\":true,
    }");
Response response = client.target("https://rest.smartvault.com/nodes/entity/path")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .put(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const createEntityJavascript = `var request = new XMLHttpRequest();

request.open('PUT', 'https://rest.smartvault.com/nodes/entity/path');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {  
    'entity':{  
        'meta_data':{  
            'entity_definition':'SmartVault.Accounting.Client',
            'name':'Batman'
        },
        'smart_vault':{  
            'accounting':{  
                'client':{  
                'persons':[  
                    {  
                        'names':[  
                            {  
                            'FirstName':'Bruce',
                            'MiddleName':  ''                          
                            'LastName':'Wayne'
                            }
                        ]
                     }
                ],
                'type_qualifier':'Individual',
                'private':false
                }
            }
        }
     },
    'upsert':true,
    };

request.send(JSON.stringify(body));`;
export const createEntityNode = `var request = require('request');

request({
  method: 'PUT',
  url: 'https://rest.smartvault.com/nodes/entity/path',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  },
  body: "{  
    \\"entity\\":{  
        \\"meta_data\\":{  
            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",
            \\"name\\":\\"Batman\\"
        },
        \\"smart_vault\\":{  
            \\"accounting\\":{  
                \\"client\\":{  
                \\"persons\\":[  
                    {  
                        \\"names\\":[  
                            {  
                            \\"FirstName\\":\\"Bruce\\",
                            \\"MiddleName\\":  \\"\\"                          
                            \\"LastName\\":\\"Wayne\\"
                            }
                        ]
                     }
                ],
                \\"type_qualifier\\":\\"Individual\\",
                \\"private\\":false
                }
            }
        }
     },
    \\"upsert\\":true,
    }"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const createEntityPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  
    "entity":{  
        "meta_data":{  
            "entity_definition":"SmartVault.Accounting.Client",
            "name":"Batman"
        },
        "smart_vault":{  
            "accounting":{  
                "client":{  
                "persons":[  
                    {  
                        "names":[  
                            {  
                            "FirstName":"Bruce",
                            "MiddleName":  ""                          
                            "LastName":"Wayne"
                            }
                        ]
                     }
                ],
                "type_qualifier":"Individual",
                "private":false
                }
            }
        }
     },
    "upsert":true,
    }';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->put("https://rest.smartvault.com/nodes/entity/path", Content => $data);

print $response->as_string;`;
export const createEntityPython = `from urllib2 import Request, urlopen

values = """
{  
    "entity":{  
        "meta_data":{  
            "entity_definition":"SmartVault.Accounting.Client",
            "name":"Batman"
        },
        "smart_vault":{  
            "accounting":{  
                "client":{  
                "persons":[  
                    {  
                        "names":[  
                            {  
                            "FirstName":"Bruce",
                            "MiddleName":  ""                          
                            "LastName":"Wayne"
                            }
                        ]
                     }
                ],
                "type_qualifier":"Individual",
                "private":false
                }
            }
        }
     },
    "upsert":true,
    }"""

headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/entity/path', data=values, headers=headers)
request.get_method = lambda: 'PUT'

response_body = urlopen(request).read()
print response_body`;
export const createEntityPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/entity/path");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "PUT");

curl_setopt($ch, CURLOPT_POSTFIELDS, {  
    \\"entity\\":{  
        \\"meta_data\\":{  
            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",
            \\"name\\":\\"Batman\\"
        },
        \\"smart_vault\\":{  
            \\"accounting\\":{  
                \\"client\\":{  
                \\"persons\\":[  
                    {  
                        \\"names\\":[  
                            {  
                            \\"FirstName\\":\\"Bruce\\",
                            \\"MiddleName\\":  \\"\\"                          
                            \\"LastName\\":\\"Wayne\\"
                            }
                        ]
                     }
                ],
                \\"type_qualifier\\":\\"Individual\\",
                \\"private\\":false
                }
            }
        }
     },
    \\"upsert\\":true,
    });

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const createEntityRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = {  
    "entity":{  
        "meta_data":{  
            "entity_definition":"SmartVault.Accounting.Client",
            "name":"Batman"
        },
        "smart_vault":{  
            "accounting":{  
                "client":{  
                "persons":[  
                    {  
                        "names":[  
                            {  
                            "FirstName":"Bruce",
                            "MiddleName":  ""                          
                            "LastName":"Wayne"
                            }
                        ]
                     }
                ],
                "type_qualifier":"Individual",
                "private":false
                }
            }
        }
     },
    "upsert":true,
    }

headers = {
  :content_type => 'application/json',
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.put 'https://rest.smartvault.com/nodes/entity/path', values, headers
puts response`;
export const createEntityGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte({  \\n    \\"entity\\":{  \\n        \\"meta_data\\":{  \\n            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",\\n            \\"name\\":\\"Batman\\"\\n        },\\n        \\"smart_vault\\":{  \\n            \\"accounting\\":{  \\n                \\"client\\":{  \\n                \\"persons\\":[  \\n                    {  \\n                        \\"names\\":[  \\n                            {  \\n                            \\"FirstName\\":\\"Bruce\\",\\n                            \\"MiddleName\\":  \\"\\"                          \\n                            \\"LastName\\":\\"Wayne\\"\\n                            }\\n                        ]\\n                     }\\n                ],\\n                \\"type_qualifier\\":\\"Individual\\",\\n                \\"private\\":false\\n                }\\n            }\\n        }\\n     },\\n    \\"upsert\\":true,\\n    })

\treq, _ := http.NewRequest("PUT", "https://rest.smartvault.com/nodes/entity/path", bytes.NewBuffer(body))

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const createEntityC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  
    \\"entity\\":{  
        \\"meta_data\\":{  
            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",
            \\"name\\":\\"Batman\\"
        },
        \\"smart_vault\\":{  
            \\"accounting\\":{  
                \\"client\\":{  
                \\"persons\\":[  
                    {  
                        \\"names\\":[  
                            {  
                            \\"FirstName\\":\\"Bruce\\",
                            \\"MiddleName\\":  \\"\\"                          
                            \\"LastName\\":\\"Wayne\\"
                            }
                        ]
                     }
                ],
                \\"type_qualifier\\":\\"Individual\\",
                \\"private\\":false
                }
            }
        }
     },
    \\"upsert\\":true,
    }", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PutAsync("nodes/entity/{path}", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const createEntityVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/entity/path"), System.Net.HttpWebRequest)

request.Method = "PUT"

request.ContentType = "application/json"
request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("  
    \\""entity\\"":{  
        \\""meta_data\\"":{  
            \\""entity_definition\\"":\\""SmartVault.Accounting.Client\\"",
            \\""name\\"":\\""Batman\\""
        },
        \\""smart_vault\\"":{  
            \\""accounting\\"":{  
                \\""client\\"":{  
                \\""persons\\"":[  
                    {  
                        \\""names\\"":[  
                            {  
                            \\""FirstName\\"":\\""Bruce\\"",
                            \\""MiddleName\\"":  \\""\\""                          
                            \\""LastName\\"":\\""Wayne\\""
                            }
                        ]
                     }
                ],
                \\""type_qualifier\\"":\\""Individual\\"",
                \\""private\\"":false
                }
            }
        }
     },
    \\""upsert\\"":true,
    ")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const createEntityGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText({  
    "entity":{  
        "meta_data":{  
            "entity_definition":"SmartVault.Accounting.Client",
            "name":"Batman"
        },
        "smart_vault":{  
            "accounting":{  
                "client":{  
                "persons":[  
                    {  
                        "names":[  
                            {  
                            "FirstName":"Bruce",
                            "MiddleName":  ""                          
                            "LastName":"Wayne"
                            }
                        ]
                     }
                ],
                "type_qualifier":"Individual",
                "private":false
                }
            }
        }
     },
    "upsert":true,
    })
response = client.put( path : "/nodes/entity/{path}",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const createEntityObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/entity/path"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"PUT"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@{  
    \\"entity\\":{  
        \\"meta_data\\":{  
            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",
            \\"name\\":\\"Batman\\"
        },
        \\"smart_vault\\":{  
            \\"accounting\\":{  
                \\"client\\":{  
                \\"persons\\":[  
                    {  
                        \\"names\\":[  
                            {  
                            \\"FirstName\\":\\"Bruce\\",
                            \\"MiddleName\\":  \\"\\"                          
                            \\"LastName\\":\\"Wayne\\"
                            }
                        ]
                     }
                ],
                \\"type_qualifier\\":\\"Individual\\",
                \\"private\\":false
                }
            }
        }
     },
    \\"upsert\\":true,
    } dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const createEntitySwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/entity/path")!
var request = URLRequest(url: url)
request.httpMethod = "PUT"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
{  
    \\"entity\\":{  
        \\"meta_data\\":{  
            \\"entity_definition\\":\\"SmartVault.Accounting.Client\\",
            \\"name\\":\\"Batman\\"
        },
        \\"smart_vault\\":{  
            \\"accounting\\":{  
                \\"client\\":{  
                \\"persons\\":[  
                    {  
                        \\"names\\":[  
                            {  
                            \\"FirstName\\":\\"Bruce\\",
                            \\"MiddleName\\":  \\"\\"                          
                            \\"LastName\\":\\"Wayne\\"
                            }
                        ]
                     }
                ],
                \\"type_qualifier\\":\\"Individual\\",
                \\"private\\":false
                }
            }
        }
     },
    \\"upsert\\":true,
    }
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// DELETE ENTITY
export const deleteEntityCurl = `curl --include \\
     --request DELETE \\
     --header "Content-Type: application/json" \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
  'https://rest.smartvault.com/nodes/entity/path'`;
export const deleteEntityJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/entity/path")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .delete();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const deleteEntityJavascript = `var request = new XMLHttpRequest();

request.open('DELETE', 'https://rest.smartvault.com/nodes/entity/path');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const deleteEntityNode = `var request = require('request');

request({
  method: 'DELETE',
  url: 'https://rest.smartvault.com/nodes/entity/path',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=='
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const deleteEntityPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");

my $response = $ua->delete("https://rest.smartvault.com/nodes/entity/path");

print $response->as_string;`;
export const deleteEntityPython = `from urllib2 import Request, urlopen

headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=='
}
request = Request('https://rest.smartvault.com/nodes/entity/path', headers=headers)
request.get_method = lambda: 'DELETE'

response_body = urlopen(request).read()
print response_body`;
export const deleteEntityPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/entity/path");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "DELETE");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const deleteEntityRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :content_type => 'application/json',
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=='
}

response = RestClient.delete 'https://rest.smartvault.com/nodes/entity/path', headers
puts response`;
export const deleteEntityGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("DELETE", "https://rest.smartvault.com/nodes/entity/path", nil)

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const deleteEntityC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  using(var response = await httpClient.DeleteAsync("nodes/entity/{path}"))
  {
 
        string responseData = await response.Content.ReadAsStringAsync();
  }
}`;
export const deleteEntityVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/entity/path"), System.Net.HttpWebRequest)

request.Method = "DELETE"

request.ContentType = "application/json"
request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const deleteEntityGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="

response = client.delete( path : "/nodes/entity/{path}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const deleteEntityObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/entity/path"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"DELETE"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const deleteEntitySwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/entity/path")!
var request = URLRequest(url: url)
request.httpMethod = "DELETE"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;
