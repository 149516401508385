import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ mode, children, ...props }) => (
	<svg viewBox="0 0 100 100" {...props}>
		{children}
		<defs />
		<path
			fill="#00bf99"
			d="m80.45677,31.26525l-15.64805,-15.64805l-41.07614,0a5.86802,5.86802 0 0 0 -5.86802,5.86802l0,58.6802a5.86802,5.86802 0 0 0 5.86802,5.86802l50.85617,0a5.86802,5.86802 0 0 0 5.86802,-5.86802l0,-48.90017z"
		/>
		<path
			fill="#fff"
			d="m58.9407,46.9133a9.78003,9.78003 0 1 1 -9.78003,-9.78003a9.78003,9.78003 0 0 1 9.78003,9.78003z"
		/>
		<path fill="#fff" d="m57.9627,68.42938l-17.60406,0l8.80203,-16.62606l8.80203,16.62606z" />
		<path fill="#00967d" d="m80.45677,31.26525l-15.64805,0l0,-15.64805l15.64805,15.64805z" />
	</svg>
));
