import { baseUrl } from "../../../../api/api";
import { Image, Table, Row, Attributes, InfoIcon, WarningIcon, List, Expander, Tabs } from "../../../../api/components";
import * as React from 'react';
export default {
  baseUrl,
  Image,
  Table,
  Row,
  Attributes,
  InfoIcon,
  WarningIcon,
  List,
  Expander,
  Tabs,
  React
};