import { Table, Row, Attributes, Expander, List, InfoIcon, WarningIcon, Tabs } from "../../../../api/components";
import { getTemplateCurl, getTemplateJava, getTemplateJavascript, getTemplateNode, getTemplatePerl, getTemplatePython, getTemplatePhp, getTemplateRuby, getTemplateGo, getTemplateC, getTemplateVB, getTemplateGroovy, getTemplateObjectiveC, getTemplateSwift, createTemplateRaw, createTemplateCurl, createTemplateJava, createTemplateJavascript, createTemplateNode, createTemplatePerl, createTemplatePython, createTemplatePhp, createTemplateRuby, createTemplateGo, createTemplateC, createTemplateVB, createTemplateGroovy, createTemplateObjectiveC, createTemplateSwift, updateTemplateRaw, updateTemplateCurl, updateTemplateJava, updateTemplateJavascript, updateTemplateNode, updateTemplatePerl, updateTemplatePython, updateTemplatePhp, updateTemplateRuby, updateTemplateGo, updateTemplateC, updateTemplateVB, updateTemplateGroovy, updateTemplateObjectiveC, updateTemplateSwift } from 'requestExamples/templates';
import * as React from 'react';
export default {
  Table,
  Row,
  Attributes,
  Expander,
  List,
  InfoIcon,
  WarningIcon,
  Tabs,
  getTemplateCurl,
  getTemplateJava,
  getTemplateJavascript,
  getTemplateNode,
  getTemplatePerl,
  getTemplatePython,
  getTemplatePhp,
  getTemplateRuby,
  getTemplateGo,
  getTemplateC,
  getTemplateVB,
  getTemplateGroovy,
  getTemplateObjectiveC,
  getTemplateSwift,
  createTemplateRaw,
  createTemplateCurl,
  createTemplateJava,
  createTemplateJavascript,
  createTemplateNode,
  createTemplatePerl,
  createTemplatePython,
  createTemplatePhp,
  createTemplateRuby,
  createTemplateGo,
  createTemplateC,
  createTemplateVB,
  createTemplateGroovy,
  createTemplateObjectiveC,
  createTemplateSwift,
  updateTemplateRaw,
  updateTemplateCurl,
  updateTemplateJava,
  updateTemplateJavascript,
  updateTemplateNode,
  updateTemplatePerl,
  updateTemplatePython,
  updateTemplatePhp,
  updateTemplateRuby,
  updateTemplateGo,
  updateTemplateC,
  updateTemplateVB,
  updateTemplateGroovy,
  updateTemplateObjectiveC,
  updateTemplateSwift,
  React
};