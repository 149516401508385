export const white = '#FFFFFF';
export const grayUltraLight = '#FCFBFA';
export const grayExtraLight = '#F5F6F7';
export const grayLight = '#CED4DE';
export const gray = '#67788a';
export const grayDark = '#2D3747';
export const grayExtraDark = '#1D2330';
export const dark = '#181D28';
export const darker = '#13161F';
export const black = '#000000';
export const blueLight = '#e9f2fa';
export const accent = '#00bf99';
export const negative = '#EB4D4B';
export const primary = '#00bf99';
export const primaryAlt = '#47966c';
export const secondary = '#00967d';
export const secondaryAlt = '#3c76e9';
export const darkBlue = '#003894';
export const purple = '#7b47b3';
export const pink = '#d86187';
export const red = '#c44358';
export const yellow = '#f2c849';
export const orange = '#e38f4d';
export const brown = '#ad8e63';
