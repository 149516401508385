import React, { FC, useState } from 'react';
import { Container, Copy, Tab, TabList, TabPanel, CopyToClipboardButton, Check } from './styles';
import Props from './types';
import { Code } from 'gatsby-theme-docz/src/components/Code';

const Tabs: FC<Props> = ({ items, ...rest }) => {
	const [copied, setCopied] = useState('');
	return (
		<Container defaultTab={items[0].tabTitle} {...rest}>
			<TabList>
				{items.map(({ tabTitle }) => {
					return (
						<Tab key={`tab_${tabTitle}`} tabFor={tabTitle}>
							{tabTitle}
						</Tab>
					);
				})}
			</TabList>
			{items.map(({ tabTitle, tabInfo }) => {
				return (
					<TabPanel key={`panel_${tabTitle}`} tabId={tabTitle}>
						{copied === tabInfo ? (
							<Check />
						) : (
							<div title="Copy content to clipboard">
								<CopyToClipboardButton
									text={tabInfo}
									onCopy={() => {
										setCopied(tabInfo);
										setTimeout(() => setCopied(''), 1500);
									}}
								>
									<Copy />
								</CopyToClipboardButton>
							</div>
						)}
						<Code className={'tsx'}>
							{`
${tabInfo}
`}
						</Code>
					</TabPanel>
				);
			})}
		</Container>
	);
};

export default Tabs;
