import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	> * + * {
		margin-top: 1.25rem;
	}
`;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	&:last-child {
		margin-bottom: 0.75rem;
	}
`;
export const Title = styled.span`
	align-items: baseline;
	display: flex;
`;
export const Name = styled.span`
	font-size: 0.85rem;
	font-weight: 600;
	margin-right: 0.25rem;
	code {
		font-family: 'Inconsolata', sans-serif;
	}
`;
export const Type = styled.span`
	color: #a3acb9;
	font-size: 0.75rem;
`;
export const Description = styled.span`
	color: var(--theme-ui-colors-sidebar-navGroup, #67788a);
	display: inline;
	font-size: 0.875rem;
	margin-top: 0.25rem;
	white-space: pre-wrap;
`;
export const Values = styled.span`
	margin-left: 0.25rem;
`;
