import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, InfoIcon, List, WarningIcon, Expander } from './components';
export const baseUrl = [{
  param: 'https://rest.smartvault.com'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  baseUrl,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <Row mdxType="Row">
	<div>
		SmartVault's REST API is a service for developers using the SmartVault storage system. The API provides web,
		mobile, and on-premises applications the ability to easily access SmartVault's document management capabilities.
	</div>
	<Table title="Base url" rows={baseUrl} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "languages"
    }}>{`Languages`}</h2>
    <p>{`The SmartVault API supports JSON, XML and the Google Protocol Buffer format, with XML being the default.`}</p>
    <InfoIcon mdxType="InfoIcon" /> Add the following header to your requests if you want to get JSON responses: "Accept: application/json".
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> Through the documentation, you'll find tables with API requests in different languages; all of this are done
using the header above to get the response in JSON format.
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> Use <strong>application/x-protobuf</strong> if you want to use the Google Protocol Buffer format.
    <br />
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "requests-query-parameters"
    }}>{`Requests query parameters`}</h2>
    <p>{`SmartVault API exposes some query parameters applicable to any request that helps returning extra parameters or to identify the entity structure.`}<br /><br /></p>
    <List values={[{
      title: 'The "eprop" query parameter',
      value: 'Tells the server to return extended property information in the results. It may be set to "0", "1", "true" or "false". Default is false.'
    }]} mdxType="List" />
    <br />
    <Expander title="eprop prop difference" mdxType="Expander">
      <Row codeExamples mdxType="Row">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Request to /nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm
{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.Firm",
        "createdOn": "2020-06-30T08:12:18-05:00",
        "modifiedOn": "2020-06-30T08:12:18-05:00",
        "uri": "/nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm",
        "your_permissions": 64
    }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Request to /nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm?eprop=true
{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.Firm",
        "createdOn": "2020-06-30T08:06:47-05:00",
        "modifiedOn": "2020-06-30T08:06:47-05:00",
        "uri": "/nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm",
        "your_permissions": 64,
        "entityDefinitionExProperties": {
            "api_name": "SmartVault.Accounting.Firm",
            "label": "Firm",
            "plural_label": "Firms",
            "scope": "GLOBAL"
        }
    }
}
`}</code></pre>
      </Row>
    </Expander>
    <br /><br />
    <List values={[{
      title: 'The "vprop" query parameter',
      value: 'Tells the server to return extended version information in the results. It may be set to "0", "1", "true" or "false". Default is false.'
    }]} mdxType="List" />
    <br />
    <Expander title="vprop prop difference" mdxType="Expander">
      <Row codeExamples mdxType="Row">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Request to /nodes/pth/SmartVault Account
{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault Account",
        "createdOn": "2020-04-27T07:45:52-06:00",
        "modifiedOn": "2020-04-27T07:45:52-06:00",
        "uri": "/nodes/pth/SmartVault Account",
        "dav_uri": "/nodes/pth/SmartVault Account",
        "your_permissions": 22579317,
        "nodeType": "AccountNodeType"
    }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Request to /nodes/pth/SmartVault Account?vprop=true
{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault Account",
        "createdOn": "2020-04-27T07:45:52-06:00",
        "modifiedOn": "2020-04-27T07:45:52-06:00",
        "uri": "/nodes/pth/SmartVault Account",
        "dav_uri": "/nodes/pth/SmartVault Account",
        "your_permissions": 22579317,
        "nodeType": "AccountNodeType",
        "versionExProperties": {
            "uuid": "h4bEql3mPESe5dwNoGfMVw",
            "version": 8,
            "type": "OrganizationalUnit",
            "parent_uuid": "Wrwl5edDwEGTLC9H0fUUGg",
            "parent_type": "RootObject"
        }
    }
}
`}</code></pre>
      </Row>
    </Expander>
    <br /><br />
    <List values={[{
      title: 'The "children" query parameter',
      value: 'Specifies the depth of children to fetch back. 0 is just the element specified, and 1 is just the direct children. Can be any integer value "0" or greater. Default is 0.'
    }]} mdxType="List" />
    <br />
    <Expander title="children prop difference" mdxType="Expander">
      <Row codeExamples mdxType="Row">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Request to /nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm
{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.Firm",
        "createdOn": "2020-06-30T08:12:18-05:00",
        "modifiedOn": "2020-06-30T08:12:18-05:00",
        "uri": "/nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm",
        "your_permissions": 64
    }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Request to /nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm?children=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.Firm",
        "createdOn": "2020-06-30T09:42:23-05:00",
        "modifiedOn": "2020-06-30T09:42:23-05:00",
        "uri": "/nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm",
        "your_permissions": 64,
        "total_children": 2,
        "children": [
            {
                "name": "SmartVault.Accounting.FirmToEmployee",
                "createdOn": "2020-06-30T09:42:23-05:00",
                "modifiedOn": "2020-06-30T09:42:23-05:00",
                "uri": "/nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmToEmployee",
                "your_permissions": 64
            },
            {
                "name": "SmartVault.Accounting.FirmClient",
                "createdOn": "2020-06-30T09:42:23-05:00",
                "modifiedOn": "2020-06-30T09:42:23-05:00",
                "uri": "/nodes/entity-definition/SmartVault Account/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmClient",
                "your_permissions": 64
            }
        ]
    }
}
`}</code></pre>
      </Row>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "requests-response-structure"
    }}>{`Requests response structure`}</h2>
    <p>{`By default, any SmartVault API call will return the following object:`}<br /></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "error": {
        "success": boolean
    },
    "message": { } //This message object will show only if the request was done correctly without any errors; more info below.
}
`}</code></pre>
    <InfoIcon mdxType="InfoIcon" /> For a successful API request call, the result object will have the "success" property of the "error" object
will be set to "true" and the "message" object will contain a <a href="/api/terminology#node-response" target="_blank">
	node response object
    </a> plus any specific property of the element being browsed.
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> For an API call where any errors occurred, the "success" property of the "error" object will be set to "false",
the "message" object won't show at all in the response and an <a href="/api/errors#error-object" target="_blank">
	error object
    </a> structure will be present in the response.
    <br />
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "retrieving-plan-type"
    }}>{`Retrieving plan type`}</h2>
    <p>{`If you know which kind of `}<a parentName="p" {...{
        "href": "https://www.smartvault.com/pricing/"
      }}>{`pricing plan`}</a>{` you are using, know that "SmartVault for Business" is relative to non structured plans while "SmartVault for Accountants" will yield in a structured plan.`}<br /><br /><br />{`
In case you don't know which pricing plan you are using, you can always check using the following API request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`https://rest.smartvault.com/nodes/pth?children=1&eprop=true
`}</code></pre>
    <p>{`This call will return information about the account structure.`}<br /></p>
    <Expander mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Example of return response of the previous stated request
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2020-04-27T08:45:52Z",
        "modifiedOn": "2020-04-27T08:45:52Z",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account",
                "createdOn": "2020-04-27T07:45:52-06:00",
                "modifiedOn": "2020-04-27T07:45:52-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType",
                "accountExProperties": {
                    "available_user_licenses": 2,
                    "available_diskspace_bytes": 536870912000,
                    "name": "SmartVault Account",
                    "features": [
                        {
                            "key": "FeatureContainers",
                            "value": true
                        },
                        {
                            "key": "FeatureCreateFolder",
                            "value": true
                        },
                        {
                            "key": "FeatureUserFolders",
                            "value": true
                        },
                        {
                            "key": "FeatureAccountingPackage",
                            "value": true
                        },
                        {
                            "key": "FeatureCustomBranding",
                            "value": true
                        }
                        ...
                    ]
                }
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Search for the "accountExProperties" object in the result.`}</p>
    <WarningIcon mdxType="WarningIcon" /> You need to specify both query parameters "children" and "eprop" for it to show up.
    <br />
    <br />
If the key "FeatureAccountingPackage" in the "features" object is set to true, that means you are working with a Structured
plan, otherwise, you are dealing with the non structured one.
    <br />
    <br />
    <p>{`More info about account types `}<a parentName="p" {...{
        "href": "/api/terminology#account-types"
      }}>{`here`}</a></p>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "best-practices-and-security-considerations"
    }}>{`Best practices and security considerations`}</h2>
    <List values={[{
      title: 'Use a TLS library that validates server identity.',
      value: 'An improved implementation would ensure the identity of the SmartVault host. Perform this by retrieving the X509 certificate associated with smartvault.com and use it to validate the endpoint.'
    }, {
      title: 'Access tokens should be carefully managed.',
      value: 'During the OAuth autonomous flow, you will be granted an access token capable of impersonating the target User Id. This token will expire; however, be aware of malicious browser plug-ins that could be installed by an end user to sniff out this token by accessing the DOM. These tokens should be restricted in what they are allowed to perform and carefully protected.'
    }, {
      title: 'Revoke access when no longer needed.',
      value: 'You can control whether or not your client id is valid by logging into the SmartVault developer portal and changing settings. Further, you can control what activities this client id is allowed to perform. Restrict access to the bare necessity.'
    }, {
      title: 'Properly manage the RSA private key used to perform the autonomous OAuth flow.',
      value: 'Different keys should be generated for testing vs. production. Production keys should be properly managed and access should be restricted to the minimum staff members possible. With access to this key, others could impersonate your application and perform malicious activities.'
    }, {
      title: 'Rotate your RSA private key at least once a year.',
      value: 'Good key hygiene suggests these keys should be rotated at least once a year or anytime the key is suspected or confirmed of being shared improperly.'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      