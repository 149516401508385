const styles = {
	Container: {
		p: 4,
		maxWidth: 1280,
	},
	root: {
		fontSize: 3,
		color: 'text',
		bg: 'background',
	},
	a: {
		color: 'primary',
		textDecoration: 'none',
		'&:hover': {
			color: 'secondary',
			textDecoration: 'underline',
		},
	},
	h1: {
		fontSize: 6,
		my: '20px',
	},
	h2: {
		fontSize: 5,
	},
	h3: {
		fontSize: 4,
	},
	h4: {
		fontSize: 3,
	},
	h5: {
		fontSize: 2,
	},
	h6: {
		fontSize: 1,
	},
	li: {
		marginBottom: 1,
	},
	blockquote: {
		my: 4,
		mx: 0,
		py: 3,
		px: 4,
		bg: 'blockquote.bg',
		borderLeft: (t: { colors: { blockquote: { border: any } } }) => `5px solid ${t.colors.blockquote.border}`,
		color: 'blockquote.color',
		fontStyle: 'italic',
		'> p': {
			m: 0,
		},
	},
	code: {
		fontFamily: 'monospace',
	},
	inlineCode: {
		fontFamily: 'monospace',
	},
	pre: {
		my: '20px',
		p: 3,
		variant: 'prism',
		textAlign: 'left',
		fontFamily: 'monospace',
		borderRadius: 'radius',
	},
	table: {
		width: '100%',
		my: 4,
		borderCollapse: 'separate',
		borderSpacing: 0,
		// @ts-ignore
		[['th', 'td']]: {
			textAlign: 'left',
			py: '4px',
			pr: '4px',
			pl: 0,
			borderColor: 'muted',
			borderBottomStyle: 'solid',
		},
	},
	th: {
		verticalAlign: 'bottom',
		borderBottomWidth: '2px',
	},
	td: {
		verticalAlign: 'top',
		borderBottomWidth: '1px',
	},
	hr: {
		border: 0,
		borderTop: (t: { colors: { border: any } }) => `1.25px solid ${t.colors.border}`,
		borderBottom: 'none',
		my: 0,
		paddingBottom: '32px',
	},
};

export default styles;
