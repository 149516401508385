import { InfoIcon, List, Table, Row, Tabs, Expander, Attributes, WarningIcon } from "../../../../api/components";
import { requestPinRaw, requestPinCurl, requestPinJava, requestPinJavascript, requestPinNode, requestPinPerl, requestPinPython, requestPinPhp, requestPinRuby, requestPinGo, requestPinC, requestPinVB, requestPinGroovy, requestPinObjectiveC, requestPinSwift, requestPinDelegationTokenRaw, requestPinDelegationTokenCurl, requestPinDelegationTokenJava, requestPinDelegationTokenJavascript, requestPinDelegationTokenNode, requestPinDelegationTokenPerl, requestPinDelegationTokenPython, requestPinDelegationTokenPhp, requestPinDelegationTokenRuby, requestPinDelegationTokenGo, requestPinDelegationTokenC, requestPinDelegationTokenVB, requestPinDelegationTokenGroovy, requestPinDelegationTokenObjectiveC, requestPinDelegationTokenSwift } from 'requestExamples/authorization';
import * as React from 'react';
export default {
  InfoIcon,
  List,
  Table,
  Row,
  Tabs,
  Expander,
  Attributes,
  WarningIcon,
  requestPinRaw,
  requestPinCurl,
  requestPinJava,
  requestPinJavascript,
  requestPinNode,
  requestPinPerl,
  requestPinPython,
  requestPinPhp,
  requestPinRuby,
  requestPinGo,
  requestPinC,
  requestPinVB,
  requestPinGroovy,
  requestPinObjectiveC,
  requestPinSwift,
  requestPinDelegationTokenRaw,
  requestPinDelegationTokenCurl,
  requestPinDelegationTokenJava,
  requestPinDelegationTokenJavascript,
  requestPinDelegationTokenNode,
  requestPinDelegationTokenPerl,
  requestPinDelegationTokenPython,
  requestPinDelegationTokenPhp,
  requestPinDelegationTokenRuby,
  requestPinDelegationTokenGo,
  requestPinDelegationTokenC,
  requestPinDelegationTokenVB,
  requestPinDelegationTokenGroovy,
  requestPinDelegationTokenObjectiveC,
  requestPinDelegationTokenSwift,
  React
};