import { Table, Row, Attributes, Expander, Tabs, InfoIcon } from "../../../../api/components";
import { getEntityDefinitionsCurl, getEntityDefinitionsJava, getEntityDefinitionsJavascript, getEntityDefinitionsNode, getEntityDefinitionsPerl, getEntityDefinitionsPython, getEntityDefinitionsPhp, getEntityDefinitionsRuby, getEntityDefinitionsGo, getEntityDefinitionsC, getEntityDefinitionsVB, getEntityDefinitionsGroovy, getEntityDefinitionsObjectiveC, getEntityDefinitionsSwift } from 'requestExamples/entities';
import * as React from 'react';
export default {
  Table,
  Row,
  Attributes,
  Expander,
  Tabs,
  InfoIcon,
  getEntityDefinitionsCurl,
  getEntityDefinitionsJava,
  getEntityDefinitionsJavascript,
  getEntityDefinitionsNode,
  getEntityDefinitionsPerl,
  getEntityDefinitionsPython,
  getEntityDefinitionsPhp,
  getEntityDefinitionsRuby,
  getEntityDefinitionsGo,
  getEntityDefinitionsC,
  getEntityDefinitionsVB,
  getEntityDefinitionsGroovy,
  getEntityDefinitionsObjectiveC,
  getEntityDefinitionsSwift,
  React
};