import { Image, Table, Row, Attributes, Expander, List, Tabs, WarningIcon } from "../../../../api/components";
import { InfoIcon } from "../../../../api/components/Icons";
import { getFilesCurl, getFilesJava, getFilesJavascript, getFilesNode, getFilesPerl, getFilesPython, getFilesPhp, getFilesRuby, getFilesGo, getFilesC, getFilesVB, getFilesGroovy, getFilesObjectiveC, getFilesSwift, deleteFilesCurl, deleteFilesJava, deleteFilesJavascript, deleteFilesNode, deleteFilesPerl, deleteFilesPython, deleteFilesPhp, deleteFilesRuby, deleteFilesGo, deleteFilesC, deleteFilesVB, deleteFilesGroovy, deleteFilesObjectiveC, deleteFilesSwift, manageFilesRaw, manageFilesCurl, manageFilesJava, manageFilesJavascript, manageFilesNode, manageFilesPerl, manageFilesPython, manageFilesPhp, manageFilesRuby, manageFilesGo, manageFilesC, manageFilesVB, manageFilesGroovy, manageFilesObjectiveC, manageFilesSwift, upload1FilesRaw, upload1FilesCurl, upload1FilesJava, upload1FilesJavascript, upload1FilesNode, upload1FilesPerl, upload1FilesPython, upload1FilesPhp, upload1FilesRuby, upload1FilesGo, upload1FilesC, upload1FilesVB, upload1FilesGroovy, upload1FilesObjectiveC, upload1FilesSwift, upload2FilesRaw, upload2FilesCurl, upload2FilesJava, upload2FilesJavascript, upload2FilesNode, upload2FilesPerl, upload2FilesPython, upload2FilesPhp, upload2FilesRuby, upload2FilesGo, upload2FilesC, upload2FilesVB, upload2FilesGroovy, upload2FilesObjectiveC, upload2FilesSwift, upload3FilesRaw, upload3FilesCurl, upload3FilesJava, upload3FilesJavascript, upload3FilesNode, upload3FilesPerl, upload3FilesPython, upload3FilesPhp, upload3FilesRuby, upload3FilesGo, upload3FilesC, upload3FilesVB, upload3FilesGroovy, upload3FilesObjectiveC, upload3FilesSwift, downloadFilesCurl, downloadFilesJava, downloadFilesJavascript, downloadFilesNode, downloadFilesPerl, downloadFilesPython, downloadFilesPhp, downloadFilesRuby, downloadFilesGo, downloadFilesC, downloadFilesVB, downloadFilesGroovy, downloadFilesObjectiveC, downloadFilesSwift } from 'requestExamples/files';
import * as React from 'react';
export default {
  Image,
  Table,
  Row,
  Attributes,
  Expander,
  List,
  Tabs,
  WarningIcon,
  InfoIcon,
  getFilesCurl,
  getFilesJava,
  getFilesJavascript,
  getFilesNode,
  getFilesPerl,
  getFilesPython,
  getFilesPhp,
  getFilesRuby,
  getFilesGo,
  getFilesC,
  getFilesVB,
  getFilesGroovy,
  getFilesObjectiveC,
  getFilesSwift,
  deleteFilesCurl,
  deleteFilesJava,
  deleteFilesJavascript,
  deleteFilesNode,
  deleteFilesPerl,
  deleteFilesPython,
  deleteFilesPhp,
  deleteFilesRuby,
  deleteFilesGo,
  deleteFilesC,
  deleteFilesVB,
  deleteFilesGroovy,
  deleteFilesObjectiveC,
  deleteFilesSwift,
  manageFilesRaw,
  manageFilesCurl,
  manageFilesJava,
  manageFilesJavascript,
  manageFilesNode,
  manageFilesPerl,
  manageFilesPython,
  manageFilesPhp,
  manageFilesRuby,
  manageFilesGo,
  manageFilesC,
  manageFilesVB,
  manageFilesGroovy,
  manageFilesObjectiveC,
  manageFilesSwift,
  upload1FilesRaw,
  upload1FilesCurl,
  upload1FilesJava,
  upload1FilesJavascript,
  upload1FilesNode,
  upload1FilesPerl,
  upload1FilesPython,
  upload1FilesPhp,
  upload1FilesRuby,
  upload1FilesGo,
  upload1FilesC,
  upload1FilesVB,
  upload1FilesGroovy,
  upload1FilesObjectiveC,
  upload1FilesSwift,
  upload2FilesRaw,
  upload2FilesCurl,
  upload2FilesJava,
  upload2FilesJavascript,
  upload2FilesNode,
  upload2FilesPerl,
  upload2FilesPython,
  upload2FilesPhp,
  upload2FilesRuby,
  upload2FilesGo,
  upload2FilesC,
  upload2FilesVB,
  upload2FilesGroovy,
  upload2FilesObjectiveC,
  upload2FilesSwift,
  upload3FilesRaw,
  upload3FilesCurl,
  upload3FilesJava,
  upload3FilesJavascript,
  upload3FilesNode,
  upload3FilesPerl,
  upload3FilesPython,
  upload3FilesPhp,
  upload3FilesRuby,
  upload3FilesGo,
  upload3FilesC,
  upload3FilesVB,
  upload3FilesGroovy,
  upload3FilesObjectiveC,
  upload3FilesSwift,
  downloadFilesCurl,
  downloadFilesJava,
  downloadFilesJavascript,
  downloadFilesNode,
  downloadFilesPerl,
  downloadFilesPython,
  downloadFilesPhp,
  downloadFilesRuby,
  downloadFilesGo,
  downloadFilesC,
  downloadFilesVB,
  downloadFilesGroovy,
  downloadFilesObjectiveC,
  downloadFilesSwift,
  React
};