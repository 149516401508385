import styled, { css } from 'styled-components';
import { ArrowDownCircle } from 'react-feather';
import { dark } from 'theme/colors';

export const Container = styled.div<{ show: boolean }>`
	background-color: ${dark};
	border-radius: 0.75rem;
	-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 0.0625rem 0.1875rem;
	-moz-box-shadow: rgba(0,0,0,0.3) 0 0.0625rem 0.1875rem;
	box-shadow: rgba(0, 0, 0, 0.3) 0 0.0625rem 0.1875rem;
	transition: all 0.25s linear;
	width: auto;
	@media (max-width: 767px) {
		margin: auto;
	}
	@media (max-width: 375px) {
		width: auto;
	}
	${({ show }) =>
		!show &&
		css`
			@media (min-width: 768px) {
				width: 50%;
			}
			@media (min-width: 1024px) {
				width: 30%;
			}
			&:hover {
				filter: brightness(80%);
			}
		`}
`;

export const Handler = styled.div<{ show: boolean }>`
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 1rem 2rem;
	${({ show }) =>
		show &&
		css`
			border-bottom: 0.0625rem solid #3c4257;
		`}
`;

export const Title = styled.h3`
	font-size: 0.95rem;
	font-weight: normal;
	opacity: 0.9;
	margin: 0;
`;

export const Arrow = styled(ArrowDownCircle)<{ $show: boolean }>`
	flex-shrink: 0;
	margin-left: 1rem;
	transition: 0.25s;
	${({ $show }) =>
		$show &&
		css`
			transform: rotate(180deg);
		`}
`;

export const Children = styled.div<{ $extraPadding?: boolean }>`
	${({ $extraPadding }) =>
		$extraPadding &&
		css`
			padding: 2rem;
		`};
	pre {
		margin-top: 0;
		margin-bottom: 0;
		padding: 1rem 1.75rem;
	}
`;
