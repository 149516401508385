import * as colors from './colors';

export const light = {
	...colors,
	primary: colors.accent,
	text: colors.grayDark,
	muted: colors.gray,
	link: colors.accent,
	background: colors.white,
	border: colors.grayLight,
	header: {
		bg: colors.darker,
		text: colors.grayLight,
		border: colors.grayDark,
		button: {
			bg: colors.accent,
			color: colors.white,
		},
	},
	sidebar: {
		bg: colors.white,
		navGroup: colors.grayDark,
		navLink: colors.grayDark,
		navLinkActive: colors.accent,
		tocLink: colors.gray,
		tocLinkActive: colors.grayExtraDark,
	},
};

export const dark = {
	...colors,
	primary: colors.accent,
	text: colors.grayExtraLight,
	muted: colors.gray,
	link: colors.accent,
	background: colors.grayExtraDark,
	border: colors.grayDark,
	header: {
		bg: colors.darker,
		text: colors.grayLight,
		border: colors.grayDark,
		button: {
			bg: colors.accent,
			color: colors.white,
		},
	},
	sidebar: {
		bg: colors.grayExtraDark,
		navGroup: colors.gray,
		navLink: colors.grayLight,
		navLinkActive: colors.accent,
		tocLink: colors.gray,
		tocLinkActive: colors.grayLight,
	},
};
