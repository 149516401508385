import { Table, Row, Attributes, Expander, Tabs } from "../../../../api/components";
import { getEntityCurl, getEntityJava, getEntityJavascript, getEntityNode, getEntityPerl, getEntityPython, getEntityPhp, getEntityRuby, getEntityGo, getEntityC, getEntityVB, getEntityGroovy, getEntityObjectiveC, getEntitySwift } from 'requestExamples/entities';
import * as React from 'react';
export default {
  Table,
  Row,
  Attributes,
  Expander,
  Tabs,
  getEntityCurl,
  getEntityJava,
  getEntityJavascript,
  getEntityNode,
  getEntityPerl,
  getEntityPython,
  getEntityPhp,
  getEntityRuby,
  getEntityGo,
  getEntityC,
  getEntityVB,
  getEntityGroovy,
  getEntityObjectiveC,
  getEntitySwift,
  React
};