import { Table, Row, Attributes, Expander, InfoIcon, List, WarningIcon } from "../../../../api/components";
import { deleteEntityCurl, deleteEntityJava, deleteEntityJavascript, deleteEntityNode, deleteEntityPerl, deleteEntityPython, deleteEntityPhp, deleteEntityRuby, deleteEntityGo, deleteEntityC, deleteEntityVB, deleteEntityGroovy, deleteEntityObjectiveC, deleteEntitySwift } from 'requestExamples/entities';
import * as React from 'react';
export default {
  Table,
  Row,
  Attributes,
  Expander,
  InfoIcon,
  List,
  WarningIcon,
  deleteEntityCurl,
  deleteEntityJava,
  deleteEntityJavascript,
  deleteEntityNode,
  deleteEntityPerl,
  deleteEntityPython,
  deleteEntityPhp,
  deleteEntityRuby,
  deleteEntityGo,
  deleteEntityC,
  deleteEntityVB,
  deleteEntityGroovy,
  deleteEntityObjectiveC,
  deleteEntitySwift,
  React
};