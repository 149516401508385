import styled, { css } from 'styled-components';
import { darker, grayDark, gray, grayLight } from 'theme/colors';
import { MenuIcon as DefaultMenu } from 'docs/components/Icons';
import { Link } from 'gatsby';

export const Container = styled.div`
	background-color: ${darker};
	position: relative;
	z-index: 1;
	border-bottom: 1px solid ${grayDark};
`;

export const Content = styled.div`
	align-items: center;
	display: flex;
	height: 5rem;
	justify-content: space-between;
	padding: 0 2.5rem 0 1.5rem;
	position: relative;
`;

export const MenuIcon = styled.div`
	display: block;
	position: absolute;
	top: calc(100% + 15px);
	left: 1.875rem;
	@media (min-width: 920px) {
		display: none;
	}
`;

export const Button = styled.button`
	background: transparent;
	border: none;
	color: ${grayLight};
	opacity: 0.5;
	cursor: pointer;
	padding: 0;
	outline: none;
	&:hover {
		cursor: pointer;
	}
`;

export const HeaderMenu = styled.button<{ header?: boolean }>`
	background: transparent;
	border: none;
	cursor: pointer;
	display: block;
	padding: 0;
	outline: none;
	@media (min-width: 768px) {
		display: none;
	}
`;

export const Menu = styled(DefaultMenu)`
	color: ${gray};
	height: 1.5625rem;
	width: 1.5625rem;
`;

const openMenuStyles = css`
	background: var(--theme-ui-colors-background, #1d2330);
	border-radius: 0.5rem;
	box-shadow: rgba(0, 0, 0, 0.3) 0 0.0625rem 0.1875rem;
	display: flex;
	padding: 1rem;
`;

const closedMenuStyles = css`
	background: transparent;
	border: none;
	border-radius: 0;
	display: none;
	padding: 0;
`;

export const LinksContainer = styled.div<{ openMenu?: boolean }>`
	align-items: center;
	display: flex;
	flex-direction: row;
	position: initial;
	@media (max-width: 767px) {
		align-items: flex-start;
		flex-direction: column;
		min-width: 5rem;
		position: absolute;
		right: 2rem;
		top: 3.75rem;
		z-index: 9999;
		${({ openMenu }) =>
	openMenu
		? css`
						${openMenuStyles}
				  `
		: css`
						${closedMenuStyles}
				  `};
	}
`;

const commonHeaderLinks = css`
	border-radius: 0.75rem;
	color: ${grayLight};
	cursor: pointer;
	font-weight: 500;
	padding: 1rem 0;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.25s ease-in-out;
	width: 100%;
	@media (min-width: 768px) {
		padding: 1rem;
		text-align: left;
	}
	&:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}
`;

export const HeaderLink = styled.a`
	${commonHeaderLinks};
`;

export const HeaderInternalLink = styled(Link)`
	${commonHeaderLinks};
`;

export const Backdrop = styled.div<{ openMenu?: boolean }>`
	background: rgba(0, 0, 0, 0.5);
	bottom: 0;
	display: none;
	height: 100vh;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
	${({ openMenu }) =>
	openMenu &&
	css`
			display: block;
		`};
	@media (min-width: 768px) {
		display: none;
	}
`;
