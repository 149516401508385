import { Table, Row, Attributes, Expander, WarningIcon, Tabs } from "../../../../api/components";
import { getEmailTemplatesCurl, getEmailTemplatesJava, getEmailTemplatesJavascript, getEmailTemplatesNode, getEmailTemplatesPerl, getEmailTemplatesPython, getEmailTemplatesPhp, getEmailTemplatesRuby, getEmailTemplatesGo, getEmailTemplatesC, getEmailTemplatesVB, getEmailTemplatesGroovy, getEmailTemplatesObjectiveC, getEmailTemplatesSwift, updateEmailTemplatesRaw, updateEmailTemplatesCurl, updateEmailTemplatesJava, updateEmailTemplatesJavascript, updateEmailTemplatesNode, updateEmailTemplatesPerl, updateEmailTemplatesPython, updateEmailTemplatesPhp, updateEmailTemplatesRuby, updateEmailTemplatesGo, updateEmailTemplatesC, updateEmailTemplatesVB, updateEmailTemplatesGroovy, updateEmailTemplatesObjectiveC, updateEmailTemplatesSwift } from 'requestExamples/email-templates';
import * as React from 'react';
export default {
  Table,
  Row,
  Attributes,
  Expander,
  WarningIcon,
  Tabs,
  getEmailTemplatesCurl,
  getEmailTemplatesJava,
  getEmailTemplatesJavascript,
  getEmailTemplatesNode,
  getEmailTemplatesPerl,
  getEmailTemplatesPython,
  getEmailTemplatesPhp,
  getEmailTemplatesRuby,
  getEmailTemplatesGo,
  getEmailTemplatesC,
  getEmailTemplatesVB,
  getEmailTemplatesGroovy,
  getEmailTemplatesObjectiveC,
  getEmailTemplatesSwift,
  updateEmailTemplatesRaw,
  updateEmailTemplatesCurl,
  updateEmailTemplatesJava,
  updateEmailTemplatesJavascript,
  updateEmailTemplatesNode,
  updateEmailTemplatesPerl,
  updateEmailTemplatesPython,
  updateEmailTemplatesPhp,
  updateEmailTemplatesRuby,
  updateEmailTemplatesGo,
  updateEmailTemplatesC,
  updateEmailTemplatesVB,
  updateEmailTemplatesGroovy,
  updateEmailTemplatesObjectiveC,
  updateEmailTemplatesSwift,
  React
};