import baseTheme from 'gatsby-theme-docz/src/theme/index';
import { merge } from 'lodash/fp';
import * as modes from './modes';
import prism from './prism';
import styles from './styles';

export default merge(baseTheme, {
	initialColorMode: 'dark',
	// Show errors above playground editor
	showLiveError: true,
	// Show preview of the code inside playground
	showLivePreview: true,
	// Show editor when a playground is rendered
	showPlaygroundEditor: true,
	// Show dark/light mode switch toggle in header
	showDarkModeSwitch: false,
	// Display edit this page button on every page
	showMarkdownEditButton: false,
	// Wrap the playground editor and preview in iframes to avoid style/script collisions
	useScopingInPlayground: false,
	fonts: {
		body: "'Lato', sans-serif",
		heading: "'Lato', sans-serif",
		monospace: 'Inconsolata',
	},
	fontSizes: [10, 14, 14, 16, 22, 26, 40, 58],
	colors: {
		...modes.light,
		modes: {
			dark: modes.dark,
		},
	},
	styles,
	prism,
});
