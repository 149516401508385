import { Copy as DefaultCopy } from 'react-feather';
import styled from 'styled-components';

export const Copy = styled(DefaultCopy)`
	color: #00bf99;
	cursor: pointer;
	flex-shrink: 0;
	height: 1rem;
	transition: filter 0.2s ease-in-out;
	width: 1rem;
	:hover {
		filter: brightness(125%);
	}
`;
