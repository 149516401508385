import React, { FC, useState } from 'react';
import { Container, Handler, Arrow, Title, Children } from './styles';
import { Props } from './types';

const Expander: FC<Props> = ({ title, children, ...rest }) => {
	const [show, setShow] = useState(false);

	const toggleShow = () => {
		setShow(!show);
	};

	const titleLabel = {
		open: title ? `Hide ${title}` : 'Hide content',
		closed: title ? `Show ${title}` : 'Show content',
	};

	return (
		<Container show={show} {...rest}>
			<Handler show={show} onClick={toggleShow}>
				<Title>{show ? titleLabel.open : titleLabel.closed}</Title>
				<Arrow $show={show} />
			</Handler>
			{show && <Children $extraPadding={(children as any)?.props?.originalType !== 'pre'}>{children}</Children>}
		</Container>
	);
};

export default Expander;
