import styled, { css } from 'styled-components';
import { gray, grayLight } from 'theme/colors';
import { SearchIcon as DefaultSearch } from 'docs/components/Icons';

export const Container = styled.div<{ focused: boolean }>`
	align-items: center;
	background-color: rgba(255, 255, 255, 0.1);
	border: 0.0625rem solid transparent;
	border-radius: 0.5rem;
	display: inline-flex;
	margin-bottom: 1rem;
	padding: 0 0.5rem;
	transition: border 0.25s ease-in-out;
	width: 100%;
	width: -moz-available; /* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
	width: fill-available;
	:hover {
		border: 0.0625rem solid ${gray};
	}
	${({ focused }) =>
	focused &&
	css`
			border: 0.0625rem solid ${gray};
		`}
`;

export const Input = styled.input`
	background: none;
	border: none;
	color: ${grayLight};
	font-size: 0.8rem;
	outline: none;
	padding: 0.5rem;
	width: 100%;
`;

export const SearchIcon = styled(DefaultSearch)`
	color: ${gray};
`;
