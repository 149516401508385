import React, { FC } from 'react';
import { Props } from './types';
import { Container, CheckIcon, ListRow, Info, Title, Text } from './styles';

const List: FC<Props> = ({ values, inline, ...rest }) => {
	return (
		<Container inline={inline} {...rest}>
			{values.map(({ title, value }) => {
				return (
					<ListRow key={`${title}_${value}`} inline={inline}>
						<CheckIcon />
						<Info>
							<Title>{title}</Title>
							{value && <Text>{value}</Text>}
						</Info>
					</ListRow>
				);
			})}
		</Container>
	);
};

export default List;
