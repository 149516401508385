import styled, { css } from 'styled-components';
import { CopyIcon, CheckIcon } from '../Icons';
import { DataType } from './types';

export const Wrapper = styled.div`
	border-radius: 0.75rem;
	border: 0.0625rem solid #3c4257;
	-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 0.0625rem 0.1875rem;
	-moz-box-shadow: rgba(0,0,0,0.3) 0 0.0625rem 0.1875rem;
	box-shadow: rgba(0, 0, 0, 0.3) 0 0.0625rem 0.1875rem;
	display: flex;
	flex: auto;
	@media (min-width: 768px) {
		display: inline-flex;
		flex: none;
	}
`;

export const Container = styled.div`
	background-color: var(--theme-ui-colors-background, #1d2330);
	border-radius: 1rem;
	color: #a3acb9;
	flex: 1;
	font-size: 0.875rem;
	thead > tr {
		padding: 0;
	}
	tbody tr:first-child {
		padding: 1rem 1rem 0.25rem;
	}
	tbody tr:last-child {
		padding: 0.25rem 1rem 1.25rem;
	}
	tbody tr:only-child {
		padding: 0.75rem 1rem 0.75rem;
	}
`;

export const Header = styled.div`
	background-color: #3c4257;
	border-radius: 0.65rem 0.65rem 0 0;
	padding: 0.5rem 1rem;
	text-align: left;
`;

export const Title = styled.div`
	color: #a3acb9;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 500;
	letter-spacing: 0.05rem;
	margin: 0;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	// overflow: scroll;
	padding: 0.75rem 1.25rem;
`;

export const Row = styled.div<{ data: DataType }>`
	align-items: flex-start;
	display: flex;
	@media (min-width: 768px) {
		flex-direction: row;
	}
	&:not(:last-child) {
		margin: 0;
		@media (min-width: 768px) {
			margin-bottom: 0.5rem;
		}
	}
	${({ data }) =>
		(data === 'info' || data === 'entities') &&
		css`
			flex-direction: column;
			&:not(:last-child) {
				margin: 0.25rem 0;
			}
		`};
`;

export const Link = styled.a`
	background-color: transparent;
	color: #a3acb9;
	cursor: pointer;
	display: flex;
	flex: 1;
	outline: 0;
	text-decoration: none;
	&:hover {
		transition: filter 0.2s ease-in-out;
		filter: brightness(125%);
	}
	:visited,
	:hover,
	:active {
		color: inherit;
	}
`;

export const Key = styled.span<{
	param?: string;
	data: DataType;
	total?: number;
}>`
    font-weight: 600;
    margin-right: 0.5rem;
    min-width: 3rem;
    white-space: pre;
    @media (min-width: 768px) {
        margin-right: 0;
        min-width: 4rem;
        text-align: right;
        ${({ total }) =>
			total &&
			total === 1 &&
			css`
				min-width: auto;
			`};
    }
    ${({ param }) =>
		param &&
		((param.includes('GET') &&
			css`
				:first-line {
					color: #3c76e9;
				}
			`) ||
			(param.includes('PUT') &&
				css`
					:first-line {
						color: #e38f4d;
					}
				`) ||
			(param.includes('POST') &&
				css`
					:first-line {
						color: #47966c;
					}
				`) ||
			(param.includes('DELETE') &&
				css`
					:first-line {
						color: #c44358;
					}
				`))}
}    ${({ data }) =>
	(data === 'info' &&
		css`
			@media (min-width: 768px) {
				min-width: 10rem;
			}
		`) ||
	(data === 'entities' &&
		css`
			@media (min-width: 768px) {
				min-width: 16rem;
			}
		`)};
`;

export const Value = styled.span`
	color: #a3acb9;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	@media (min-width: 768px) {
		margin: 0 1rem;
	}
`;

const defaultIconsStyles = css`
	margin-left: 1rem;
	@media (min-width: 768px) {
		margin-left: auto;
	}
`;

export const Check = styled(CheckIcon)`
	${defaultIconsStyles};
`;

export const Copy = styled(CopyIcon)`
	${defaultIconsStyles};
`;

export const CopyToClipboardContainer = styled.div`
	display: flex;
	margin-left: auto;
`;
