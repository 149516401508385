import React, { FC, useState } from 'react';
import { Container, Input, SearchIcon } from './styles';

/* eslint-disable import/prefer-default-export */
export const NavSearch: FC = ({ ...rest }) => {
	const [focused, setFocused] = useState(false);
	return (
		<Container focused={focused} data-testid="nav-search">
			<SearchIcon />
			<Input
				title="Search for a documents section"
				onBlur={() => setFocused(false)}
				onFocus={() => setFocused(true)}
				{...rest}
			/>
		</Container>
	);
};
