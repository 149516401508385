import { Image, Table, Row, Attributes, Expander, Tabs, InfoIcon, WarningIcon } from "../../../../api/components";
import { getFoldersCurl, getFoldersJava, getFoldersJavascript, getFoldersNode, getFoldersPerl, getFoldersPython, getFoldersPhp, getFoldersRuby, getFoldersGo, getFoldersC, getFoldersVB, getFoldersGroovy, getFoldersObjectiveC, getFoldersSwift, createFoldersCurl, createFoldersJava, createFoldersJavascript, createFoldersNode, createFoldersPerl, createFoldersPython, createFoldersPhp, createFoldersRuby, createFoldersGo, createFoldersC, createFoldersVB, createFoldersGroovy, createFoldersObjectiveC, createFoldersSwift, deleteFoldersCurl, deleteFoldersJava, deleteFoldersJavascript, deleteFoldersNode, deleteFoldersPerl, deleteFoldersPython, deleteFoldersPhp, deleteFoldersRuby, deleteFoldersGo, deleteFoldersC, deleteFoldersVB, deleteFoldersGroovy, deleteFoldersObjectiveC, deleteFoldersSwift, manageFoldersRaw, manageFoldersCurl, manageFoldersJava, manageFoldersJavascript, manageFoldersNode, manageFoldersPerl, manageFoldersPython, manageFoldersPhp, manageFoldersRuby, manageFoldersGo, manageFoldersC, manageFoldersVB, manageFoldersGroovy, manageFoldersObjectiveC, manageFoldersSwift } from 'requestExamples/folders';
import * as React from 'react';
export default {
  Image,
  Table,
  Row,
  Attributes,
  Expander,
  Tabs,
  InfoIcon,
  WarningIcon,
  getFoldersCurl,
  getFoldersJava,
  getFoldersJavascript,
  getFoldersNode,
  getFoldersPerl,
  getFoldersPython,
  getFoldersPhp,
  getFoldersRuby,
  getFoldersGo,
  getFoldersC,
  getFoldersVB,
  getFoldersGroovy,
  getFoldersObjectiveC,
  getFoldersSwift,
  createFoldersCurl,
  createFoldersJava,
  createFoldersJavascript,
  createFoldersNode,
  createFoldersPerl,
  createFoldersPython,
  createFoldersPhp,
  createFoldersRuby,
  createFoldersGo,
  createFoldersC,
  createFoldersVB,
  createFoldersGroovy,
  createFoldersObjectiveC,
  createFoldersSwift,
  deleteFoldersCurl,
  deleteFoldersJava,
  deleteFoldersJavascript,
  deleteFoldersNode,
  deleteFoldersPerl,
  deleteFoldersPython,
  deleteFoldersPhp,
  deleteFoldersRuby,
  deleteFoldersGo,
  deleteFoldersC,
  deleteFoldersVB,
  deleteFoldersGroovy,
  deleteFoldersObjectiveC,
  deleteFoldersSwift,
  manageFoldersRaw,
  manageFoldersCurl,
  manageFoldersJava,
  manageFoldersJavascript,
  manageFoldersNode,
  manageFoldersPerl,
  manageFoldersPython,
  manageFoldersPhp,
  manageFoldersRuby,
  manageFoldersGo,
  manageFoldersC,
  manageFoldersVB,
  manageFoldersGroovy,
  manageFoldersObjectiveC,
  manageFoldersSwift,
  React
};