import { Search as DefaultSearch } from 'react-feather';
import styled from 'styled-components';

export const Search = styled(DefaultSearch)`
	color: #f5f6f7;
	flex-shrink: 0;
	height: 1rem;
	transition: filter 0.2s ease-in-out;
	width: 1rem;
	:hover {
		filter: brightness(125%);
	}
`;
