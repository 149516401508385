import React, { FC } from 'react';
import { Container, Title, SmartVaultLogo, HighLight } from './styles';
import { Props } from './types';
/* eslint-disable import/prefer-default-export */
export const Logo: FC<Props> = ({ url, title, ...rest }) => (
	<Container to={url || '/'} {...rest}>
		<SmartVaultLogo />
		<Title>SmartVault {title && <HighLight>{title}</HighLight>}</Title>
	</Container>
);
