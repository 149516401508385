// BROWSE
export const browseAppsCurl = `curl --include \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction='`;
export const browseAppsJava = `// Maven : Add these dependencies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=")
  .request(MediaType.TEXT_PLAIN_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .get();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const browseAppsJavascript = `var request = new XMLHttpRequest();

request.open('GET', 'https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=');

request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const browseAppsNode = `var request = require('request');

request({
  method: 'GET',
  url: 'https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=',
  headers: {
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const browseAppsPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->get("https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=");

print $response->as_string;`;
export const browseAppsPython = `from urllib2 import Request, urlopen

headers = {
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=', headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const browseAppsPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const browseAppsRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.get 'https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=', headers
puts response`;
export const browseAppsGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("GET", "https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=", nil)

\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const browseAppsC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
  using(var response = await httpClient.GetAsync("nodes/app/{path}{?children,acl,eprop,search,page,per_page,sort,direction,record_id}"))
  {
 
        string responseData = await response.Content.ReadAsStringAsync();
  }
}`;
export const browseAppsVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction="), System.Net.HttpWebRequest)

request.Method = "GET"

request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const browseAppsGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

response = client.get( path : "/nodes/app/{path}{?children,acl,eprop,search,page,per_page,sort,direction,record_id}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const browseAppsObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction="];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"GET"];

[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const browseAppsSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/app/{path}?children=&acl=&eprop=&search=&page=&per_page=&sort=&direction=")!
var request = URLRequest(url: url)
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// DELETE
export const deleteAppsCurl = `curl --include \\
     --request DELETE \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/app/path'`;
export const deleteAppsJava = `// Maven : Add these dependencies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/app/path")
  .request(MediaType.TEXT_PLAIN_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .delete();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const deleteAppsJavascript = `var request = new XMLHttpRequest();

request.open('DELETE', 'https://rest.smartvault.com/nodes/app/path');

request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const deleteAppsNode = `var request = require('request');

request({
  method: 'DELETE',
  url: 'https://rest.smartvault.com/nodes/app/path',
  headers: {
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const deleteAppsPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->delete("https://rest.smartvault.com/nodes/app/path");

print $response->as_string;`;
export const deleteAppsPython = `from urllib2 import Request, urlopen

headers = {
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/app/path', headers=headers)
request.get_method = lambda: 'DELETE'

response_body = urlopen(request).read()
print response_body`;
export const deleteAppsPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/app/path");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "DELETE");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const deleteAppsRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.delete 'https://rest.smartvault.com/nodes/app/path', headers
puts response`;
export const deleteAppsGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("DELETE", "https://rest.smartvault.com/nodes/app/path", nil)

\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const deleteAppsC = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("DELETE", "https://rest.smartvault.com/nodes/app/path", nil)

\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const deleteAppsVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/app/path"), System.Net.HttpWebRequest)

request.Method = "DELETE"

request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const deleteAppsGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

response = client.delete( path : "/nodes/app/{path}{?children,acl,eprop,search,page,per_page,sort,direction,record_id}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const deleteAppsObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/app/path"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"DELETE"];

[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const deleteAppsSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/app/path")!
var request = URLRequest(url: url)
request.httpMethod = "DELETE"
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;
