// RETRIEVE EMAIL TEMPLATES
export const getEmailTemplatesCurl = `curl --include \\
     --header "Content-Type: application/json" \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
  'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}'`;
export const getEmailTemplatesJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Response response = client.target("https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .get();

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const getEmailTemplatesJavascript = `var request = new XMLHttpRequest();

request.open('GET', 'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

request.send();`;
export const getEmailTemplatesNode = `var request = require('request');

request({
  method: 'GET',
  url: 'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  }}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const getEmailTemplatesPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->get("https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}");

print $response->as_string;`;
export const getEmailTemplatesPython = `from urllib2 import Request, urlopen

headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}', headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const getEmailTemplatesPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const getEmailTemplatesRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

headers = {
  :content_type => 'application/json',
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.get 'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}', headers
puts response`;
export const getEmailTemplatesGo = `package main

import (
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\treq, _ := http.NewRequest("GET", "https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}", nil)

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const getEmailTemplatesC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
  using(var response = await httpClient.GetAsync("nodes/email-template/{accountName}/{emailTemplateName}"))
  {
 
        string responseData = await response.Content.ReadAsStringAsync();
  }
}`;
export const getEmailTemplatesVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}"), System.Net.HttpWebRequest)

request.Method = "GET"

request.ContentType = "application/json"
request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

request.ContentLength = 0
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const getEmailTemplatesGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

response = client.get( path : "/nodes/email-template/{accountName}/{emailTemplateName}", headers: emptyHeaders )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const getEmailTemplatesObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"GET"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const getEmailTemplatesSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}")!
var request = URLRequest(url: url)
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// UPDATE EMAIL TEMPLATES
export const updateEmailTemplatesRaw = `Headers:

Content-Type:application/json
Authorization:Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==
Accept:application/json

Body:

{
  "update": {
    "update_subject": true,
    "subject": "Example Subject",
    "update_body": true,
    "body": "This is the body of an email template.",
    "update_display_name": true,
    "display_name": "Example Name",
    "update_description": true,
    "description": "This is an example email template."
  },
  "revert_to_default": false
}`;
export const updateEmailTemplatesCurl = `curl --include \\
     --request POST \\
     --header "Content-Type: application/json" \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --header "Accept: application/json" \\
     --data-binary "{
    \\"update\\":
    {
        \\"update_subject\\": true,
        \\"subject\\": \\"Example Subject\\",
        \\"update_body\\": true,
        \\"body\\": \\"This is the body of an email template.\\",
        \\"update_display_name\\": true,
        \\"display_name\\": \\"Example Name\\",
        \\"update_description\\": true,
        \\"description\\": \\"This is an example email template.\\"
    },
    \\"revert_to_default\\": false
}" \\
'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}'`;
export const updateEmailTemplatesJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  \\"update\\": {    \\"update_subject\\": true,    \\"subject\\": \\"Example Subject\\",    \\"update_body\\": true,    \\"body\\": \\"This is the body of an email template.\\",    \\"update_display_name\\": true,    \\"display_name\\": \\"Example Name\\",    \\"update_description\\": true,    \\"description\\": \\"This is an example email template.\\"  },  \\"revert_to_default\\": false}");
Response response = client.target("https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .post(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const updateEmailTemplatesJavascript = `var request = new XMLHttpRequest();

request.open('POST', 'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {
  'update': {
    'update_subject': true,
    'subject': 'Example Subject',
    'update_body': true,
    'body': 'This is the body of an email template.',
    'update_display_name': true,
    'display_name': 'Example Name',
    'update_description': true,
    'description': 'This is an example email template.'
  },
  'revert_to_default': false
};

request.send(JSON.stringify(body));`;
export const updateEmailTemplatesNode = `var request = require('request');

request({
  method: 'POST',
  url: 'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Accept': 'application/json'
  },
  body: "{  \\"update\\": {    \\"update_subject\\": true,    \\"subject\\": \\"Example Subject\\",    \\"update_body\\": true,    \\"body\\": \\"This is the body of an email template.\\",    \\"update_display_name\\": true,    \\"display_name\\": \\"Example Name\\",    \\"update_description\\": true,    \\"description\\": \\"This is an example email template.\\"  },  \\"revert_to_default\\": false}"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const updateEmailTemplatesPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "update": {    "update_subject": true,    "subject": "Example Subject",    "update_body": true,    "body": "This is the body of an email template.",    "update_display_name": true,    "display_name": "Example Name",    "update_description": true,    "description": "This is an example email template."  },  "revert_to_default": false}';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}", Content => $data);

print $response->as_string;`;
export const updateEmailTemplatesPython = `from urllib2 import Request, urlopen

values = """
  {
    "update": {
      "update_subject": true,
      "subject": "Example Subject",
      "update_body": true,
      "body": "This is the body of an email template.",
      "update_display_name": true,
      "display_name": "Example Name",
      "update_description": true,
      "description": "This is an example email template."
    },
    "revert_to_default": false
  }
"""

headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}', data=values, headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const updateEmailTemplatesPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_POST, TRUE);

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"update\\": {
    \\"update_subject\\": true,
    \\"subject\\": \\"Example Subject\\",
    \\"update_body\\": true,
    \\"body\\": \\"This is the body of an email template.\\",
    \\"update_display_name\\": true,
    \\"display_name\\": \\"Example Name\\",
    \\"update_description\\": true,
    \\"description\\": \\"This is an example email template.\\"
  },
  \\"revert_to_default\\": false
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const updateEmailTemplatesRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "update": {
    "update_subject": true,
    "subject": "Example Subject",
    "update_body": true,
    "body": "This is the body of an email template.",
    "update_display_name": true,
    "display_name": "Example Name",
    "update_description": true,
    "description": "This is an example email template."
  },
  "revert_to_default": false
}'

headers = {
  :content_type => 'application/json',
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :accept => 'application/json'
}

response = RestClient.post 'https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}', values, headers
puts response`;
export const updateEmailTemplatesGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"update\\": {\\n    \\"update_subject\\": true,\\n    \\"subject\\": \\"Example Subject\\",\\n    \\"update_body\\": true,\\n    \\"body\\": \\"This is the body of an email template.\\",\\n    \\"update_display_name\\": true,\\n    \\"display_name\\": \\"Example Name\\",\\n    \\"update_description\\": true,\\n    \\"description\\": \\"This is an example email template.\\"\\n  },\\n  \\"revert_to_default\\": false\\n}")

\treq, _ := http.NewRequest("POST", "https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}", bytes.NewBuffer(body))

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const updateEmailTemplatesC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"update\\": {    \\"update_subject\\": true,    \\"subject\\": \\"Example Subject\\",    \\"update_body\\": true,    \\"body\\": \\"This is the body of an email template.\\",    \\"update_display_name\\": true,    \\"display_name\\": \\"Example Name\\",    \\"update_description\\": true,    \\"description\\": \\"This is an example email template.\\"  },  \\"revert_to_default\\": false}", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PostAsync("nodes/email-template/{accountName}/{emailTemplateName}", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const updateEmailTemplatesVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}"), System.Net.HttpWebRequest)

request.Method = "POST"

request.ContentType = "application/json"
request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""update\\"": {
    \\""update_subject\\"": true,
    \\""subject\\"": \\""Example Subject\\"",
    \\""update_body\\"": true,
    \\""body\\"": \\""This is the body of an email template.\\"",
    \\""update_display_name\\"": true,
    \\""display_name\\"": \\""Example Name\\"",
    \\""update_description\\"": true,
    \\""description\\"": \\""This is an example email template.\\""
  },
  \\""revert_to_default\\"": false
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const updateEmailTemplatesGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "update": {
    "update_subject": true,
    "subject": "Example Subject",
    "update_body": true,
    "body": "This is the body of an email template.",
    "update_display_name": true,
    "display_name": "Example Name",
    "update_description": true,
    "description": "This is an example email template."
  },
  "revert_to_default": false
}')
response = client.post( path : "/nodes/email-template/{accountName}/{emailTemplateName}",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const updateEmailTemplatesObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"POST"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"update\\": {\\n    \\"update_subject\\": true,\\n    \\"subject\\": \\"Example Subject\\",\\n    \\"update_body\\": true,\\n    \\"body\\": \\"This is the body of an email template.\\",\\n    \\"update_display_name\\": true,\\n    \\"display_name\\": \\"Example Name\\",\\n    \\"update_description\\": true,\\n    \\"description\\": \\"This is an example email template.\\"\\n  },\\n  \\"revert_to_default\\": false\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const updateEmailTemplatesSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/nodes/email-template/{accountName}/{emailTemplateName}")!
var request = URLRequest(url: url)
request.httpMethod = "POST"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"update\\": {\\n    \\"update_subject\\": true,\\n    \\"subject\\": \\"Example Subject\\",\\n    \\"update_body\\": true,\\n    \\"body\\": \\"This is the body of an email template.\\",\\n    \\"update_display_name\\": true,\\n    \\"display_name\\": \\"Example Name\\",\\n    \\"update_description\\": true,\\n    \\"description\\": \\"This is an example email template.\\"\\n  },\\n  \\"revert_to_default\\": false\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;
