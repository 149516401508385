import styled from 'styled-components';
import DefaultLogo from 'components/Vectors/Logo';
import { Link } from 'gatsby';

export const Container = styled(Link)`
	align-items: center;
	color: var(--theme-ui-colors-text, #f5f6f7);
	display: flex;
	text-decoration: none;
	&:visited,
	&:hover,
	&:active {
		color: var(--theme-ui-colors-text, #f5f6f7);
	}
`;

export const Title = styled.h1`
	font-size: 1.5rem;
	line-height: 1.5;
`;

export const SmartVaultLogo = styled(DefaultLogo)`
	height: 3rem;
	width: 3rem;
`;

export const HighLight = styled.span`
	color: #00bf99;
	margin-left: 0.5rem;
`;
