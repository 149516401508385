export const javascript = `var CryptoJS = require("crypto-js");
var clientId = "client_id_for_smartvault_autonomous_authentication";

//PEM format private key to replace
var privateKey = "-----BEGIN RSA PRIVATE KEY-----\\r\\n MIIEowIBAAKCAQEA2t... -----END RSA PRIVATE KEY-----\\r\\n";

//Setup & pull in jsrsasign-js
var navigator = {};
var window = {};
eval("jsrsasign-js");

/*
//Token Format:
BASE64
(
"SLF00" + Length(UTF8(Client ID)) + UTF8(Client ID) + Length(Nonce) + Nonce 
+ 
SIGN(SHA256("SLF00" + Length(UTF8(ClientId)) + UTF8(ClientId) + Length(Nonce) + Nonce))
*/

var nonce = "nonce_for_smartvault_autonomous_authentication";
var unsignedToken = "SLF00" + String.fromCharCode(clientId.length) + clientId + String.fromCharCode(nonce.length) + nonce;

var rsa = new RSAKey();
rsa.readPrivateKeyFromPEMString(privateKey);
var hashAlgorithm = 'sha256';
var signature = rsa.sign(unsignedToken, hashAlgorithm);
var buffers = [new Buffer(unsignedToken), Buffer.from(signature, 'hex')]
var combinedBuffers = Buffer.concat(buffers);
var selfSignedToken = btoa(combinedBuffers); // Token`;
export const cChar = `using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Org.BouncyCastle.Crypto;
using Org.BouncyCastle.Crypto.Parameters;
using Org.BouncyCastle.OpenSsl;
using Org.BouncyCastle.Security;
using Org.BouncyCastle.X509;
using System.Security.Cryptography;
using System.IO;

namespace SmartVaultAuthToken
{
    public class TokenGenerator
    {
        class ByteWriter
        {
            private byte[] _array;
            private int _position;

            public ByteWriter(byte[] array)
            {
                _array = array;
                _position = 0;
            }

            public ByteWriter(byte[] array, int position)
            {
                _array = array;
                _position = position;
            }

            public byte[] Buffer { get { return _array; } }

            public void Write(byte b)
            {
                _array[_position++] = b;
            }

            public void Write(string t)
            {
                for (int i = 0; i < t.Length; ++i)
                {
                    _array[_position++] = (byte)t[i];
                }
            }

            public void WriteStrLen(string t)
            {
                int length = t == null ? 0 : t.Length;
                if (length > 255) throw new ArgumentException();

                Write((byte)length);
                if (length != 0)
                {
                    Write(t);
                }
            }

        }

        private const int AuthSchemeSize = 5;
        private const string Scheme = "SLF00";

        private RSACryptoServiceProvider GetPrivateKey(string txt)
        {
            RSACryptoServiceProvider priProvider = null;

            using (var rdr = new StringReader(txt))
            {
                var x = new PemReader(rdr);
                var y = x.ReadObject();
                RsaPrivateCrtKeyParameters pri = null;


                AsymmetricCipherKeyPair k = y as AsymmetricCipherKeyPair;
                pri = k.Private as RsaPrivateCrtKeyParameters;


                priProvider = (RSACryptoServiceProvider)RSACryptoServiceProvider.Create();
                var pria = new RSAParameters();

                // private exponent
                pria.D = pri.Exponent.ToByteArrayUnsigned();

                // exponent1
                pria.DP = pri.DP.ToByteArrayUnsigned();

                // exponent2
                pria.DQ = pri.DQ.ToByteArrayUnsigned();

                // e, the public exponent
                pria.Exponent = pri.PublicExponent.ToByteArrayUnsigned();

                // coefficent
                pria.InverseQ = pri.QInv.ToByteArrayUnsigned();

                // n, modulus
                pria.Modulus = pri.Modulus.ToByteArrayUnsigned();

                // prime1
                pria.P = pri.P.ToByteArrayUnsigned();

                // prime2
                pria.Q = pri.Q.ToByteArrayUnsigned();

                priProvider.ImportParameters(pria);
            }

            return priProvider;
        }

        private byte[] JoinArrays(byte[] array1, byte[] array2)
        {
            byte[] newArray =
                  new byte[array1.Length + array2.Length];

            Array.Copy(array1, newArray, array1.Length);
            Array.Copy(array2, 0, newArray, array1.Length, array2.Length);
            return newArray;
        }

        public string Generate(string privateKey, string clientId, string nonce)
        {

            int rawTokenSize = 1 + clientId.Length + 1 + nonce.Length + AuthSchemeSize;

            ByteWriter writer = new ByteWriter(new byte[rawTokenSize]);
            writer.Write(Scheme);
            writer.WriteStrLen(clientId);
            writer.WriteStrLen(nonce);

            byte[] buffer = writer.Buffer;
            SHA256Managed sha = new SHA256Managed();
            byte[] hash = sha.ComputeHash(buffer);

            byte[] signedData = GetPrivateKey(privateKey).SignHash(hash.ToArray(), CryptoConfig.MapNameToOID("SHA256"));


            byte[] signedToken = JoinArrays(buffer, signedData);

            return Convert.ToBase64String(signedToken);
        }

    }
}

/*
After implementing it, you'll be able to do something like:

TokenGenerator tg = new TokenGenerator();
string token = tg.Generate(privateKey, clientId, nonce);
*/
`;
