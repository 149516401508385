import React, { FC, useEffect, useRef, useState } from 'react';
import { Logo } from '../Logo';
import {
	Container,
	Content,
	Menu,
	MenuIcon,
	Button,
	LinksContainer,
	HeaderLink,
	HeaderInternalLink,
	HeaderMenu,
	Backdrop,
} from './styles';
/* eslint-disable import/prefer-default-export */
export const Header: FC = ({ ...props }) => {
	const sidebar = useRef<Element | undefined>();
	const [location, setLocation] = useState('');

	useEffect(() => {
		sidebar.current = document.querySelector('div[data-testid="sidebar"]') ?? undefined;
	}, []);

	useEffect(() => {
		if (typeof window !== `undefined`) {
			setLocation(window.location.href);
		}
	}, []);

	const handleSupportClick = () => {
		window.open('mailto:sdk@smartvault.com?subject=API Issue');
	};

	if (sidebar && sidebar.current) {
		const observer = new MutationObserver((mutations) => {
			mutations.forEach(() => {
				const transformation = window
					.getComputedStyle(sidebar.current as Element)
					.getPropertyValue('transform')
					.match(/(-?[0-9.]+)/g);
				// If sidebar menu is showing, block its scrolling
				if (transformation && transformation[4] === '0') {
					document.body.style.overflow = 'unset';
				} else {
					document.body.style.overflow = 'hidden';
				}
			});
		});

		observer.observe(sidebar.current, {
			attributes: true,
			attributeFilter: ['class'],
		});
	}

	const [openMenu, setOpenMenu] = useState(false);
	useEffect(() => {
		if (openMenu) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [openMenu]);
	return (
		<Container {...props} data-testid="header">
			{location.includes('api') && (
				<MenuIcon>
					<Button
						onClick={() => {
							(props as any).onOpen();
						}}
					>
						<Menu />
					</Button>
				</MenuIcon>
			)}
			<Content>
				<Logo />
				<LinksContainer openMenu={openMenu}>
					{/* <HeaderInternalLink to="/">Docs</HeaderInternalLink> */}
					<HeaderInternalLink to="/api">API</HeaderInternalLink>
					{/* <HeaderInternalLink to="/sdk">SDKs</HeaderInternalLink> */}
					<HeaderLink onClick={handleSupportClick}>Support</HeaderLink>
				</LinksContainer>
				<HeaderMenu
					onClick={() => {
						if (sidebar && sidebar.current) {
							const transformation = window
								.getComputedStyle(sidebar.current as Element)
								.getPropertyValue('transform')
								.match(/(-?[0-9.]+)/g);
							// If sidebar menu is showing, hide it when opening header menu
							if (transformation && transformation[4] === '0') {
								(props as any).onOpen();
							}
						}
						setOpenMenu(!openMenu);
					}}
				>
					<Menu />
				</HeaderMenu>
				<Backdrop openMenu={openMenu} onClick={() => setOpenMenu(false)} />
			</Content>
		</Container>
	);
};
