import { Info as DefaultInfo } from 'react-feather';
import styled from 'styled-components';

export const Info = styled(DefaultInfo)`
	color: #00bf99;
	flex-shrink: 0;
	height: 1rem;
	vertical-align: text-bottom;
	width: 1.75rem;
`;
