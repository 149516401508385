import React, { FC } from 'react';
import { Container, Title, Img } from './styles';
import Props from './types';

const Image: FC<Props> = ({ src, heading, alt, title, height, width, ...rest }) => {
	return (
		<Container>
			{heading && <Title>{heading}</Title>}
			<Img src={src} title={title} alt={alt} height={height} width={width} {...rest} />
		</Container>
	);
};

export default Image;
