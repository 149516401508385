import styled, { css } from 'styled-components';

const Container = styled.div<{ $objectRow?: boolean; codeExamples?: boolean }>`
	align-items: baseline;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	> *:first-child {
		@media (min-width: 768px) {
			width: 50%;
		}
		width: 100%;
	}
	> *:nth-child(2) {
		@media (min-width: 768px) {
			margin-top: 0;
			max-width: 35rem;
		}
		margin-top: 1rem;
	}

	${({ codeExamples }) =>
		codeExamples &&
		css`
			> *:first-child {
				@media (min-width: 1024px) {
					flex: 1;
					margin-right: 1rem;
					min-width: 48%;
				}
				min-width: 100%;
			}
			> *:nth-child(2) {
				@media (min-width: 1024px) {
					flex: 1;
					min-width: 48%;
				}
				max-width: unset;
				min-width: 100%;
			}
		`};

	${({ $objectRow }) =>
		$objectRow &&
		css`
			pre {
				margin-top: 0;
			}
		`};
`;

export default Container;
