// REQUEST NONCE AUTONOMOUS
export const requestNonceRaw = `Headers:

Content-Type:application/json
Accept:application/json

Body:

{
  "client_id": "SampleCRMWeb"
}`;
export const requestNonceCurl = `curl --include \\
     --request POST \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
     --data-binary "{ \\"client_id\\": \\"SampleCRMWeb\\" }" \\
'https://rest.smartvault.com/auto/auth/nonce/1'`;
export const requestNonceJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  \\"client_id\\": \\"SampleCRMWeb\\"}");
Response response = client.target("https://rest.smartvault.com/auto/auth/nonce/1")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .post(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const requestNonceJavascript = `var request = new XMLHttpRequest();

request.open('POST', 'https://rest.smartvault.com/auto/auth/nonce/1');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {
  'client_id': 'SampleCRMWeb'
};

request.send(JSON.stringify(body));`;
export const requestNonceNode = `var request = require('request');

request({
  method: 'POST',
  url: 'https://rest.smartvault.com/auto/auth/nonce/1',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  body: "{  \\"client_id\\": \\"SampleCRMWeb\\"}"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const requestNoncePerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "client_id": "SampleCRMWeb"}';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/auto/auth/nonce/1", Content => $data);

print $response->as_string;`;
export const requestNoncePython = `from urllib2 import Request, urlopen

values = """
  {
    "client_id": "SampleCRMWeb"
  }
"""

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/auto/auth/nonce/1', data=values, headers=headers)

response_body = urlopen(request).read()
print(response_body)`;
export const requestNoncePhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/auto/auth/nonce/1");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_POST, TRUE);

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"client_id\\": \\"SampleCRMWeb\\"
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const requestNonceRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "client_id": "SampleCRMWeb"
}'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.post 'https://rest.smartvault.com/auto/auth/nonce/1', values, headers
puts response`;
export const requestNonceGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"client_id\\": \\"SampleCRMWeb\\"\\n}")

\treq, _ := http.NewRequest("POST", "https://rest.smartvault.com/auto/auth/nonce/1", bytes.NewBuffer(body))

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const requestNonceC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"client_id\\": \\"SampleCRMWeb\\"}", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PostAsync("auto/auth/nonce/1", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const requestNonceVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/auto/auth/nonce/1"), System.Net.HttpWebRequest)

request.Method = "POST"

request.ContentType = "application/json"
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""client_id\\"": \\""SampleCRMWeb\\""
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const requestNonceGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "client_id": "SampleCRMWeb"
}')
response = client.post( path : "/auto/auth/nonce/1",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const requestNonceObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/auto/auth/nonce/1"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"POST"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"client_id\\": \\"SampleCRMWeb\\"\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const requestNonceSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/auto/auth/nonce/1")!
var request = URLRequest(url: url)
request.httpMethod = "POST"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"client_id\\": \\"SampleCRMWeb\\"\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// REQUEST CLIENT TOKEN
export const requestClientTokenRaw = `Headers:

Content-Type:application/json
Accept:application/json

Body:

{
  "token": "U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw=="
}`;
export const requestClientTokenCurl = `curl --include \\
     --request POST \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
     --data-binary "{ \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\" }" \\
'https://rest.smartvault.com/auto/auth/ctoken/1'`;
export const requestClientTokenJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\"}");
Response response = client.target("https://rest.smartvault.com/auto/auth/ctoken/1")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .post(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const requestClientTokenJavascript = `var request = new XMLHttpRequest();

request.open('POST', 'https://rest.smartvault.com/auto/auth/ctoken/1');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {
  'token': 'U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw=='
};

request.send(JSON.stringify(body));`;
export const requestClientTokenNode = `var request = require('request');

request({
  method: 'POST',
  url: 'https://rest.smartvault.com/auto/auth/ctoken/1',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  body: "{  \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\"}"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const requestClientTokenPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "token": "U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw=="}';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/auto/auth/ctoken/1", Content => $data);

print $response->as_string;`;
export const requestClientTokenPython = `

def get_unsigned_token(nonce: str, client_id: str) -> bytes:
  unsigned_token = "SLF00" + chr(len(client_id)) + client_id + chr(len(nonce)) + nonce
  unsigned_token = bytes(unsigned_token, "ascii")

  return unsigned_token

def sign_token(private_key_string: str, unsigned_token: bytes) -> bytes:
  #must have the pycryptodome package installed
  from Crypto.PublicKey import RSA
  from Crypto.Signature.pkcs1_15 import PKCS115_SigScheme
  from Crypto.Hash import SHA256

  private_key = RSA.import_key(private_key_string)
  hashed_token = SHA256.new(unsigned_token)
  signer = PKCS115_SigScheme(private_key)
  signature = signer.sign(hashed_token)

  return signature

def make_packet(unsigned_token: bytes, signature: bytes) -> str:
  import base64

  combined = unsigned_token + signature

  self_signed_token = base64.b64encode(combined)
  self_signed_token = self_signed_token.decode('ascii')

  return self_signed_token


#the main script starts here
import requests

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
  }
  
private_key_string="""%YOUR PEM STRING%"""
nonce = "%YOUR_NONCE%"
client_id = "%YOUR_CLIENT_ID%"

unsigned_token = get_unsigned_token(
  nonce=nonce,
  client_id=client_id
)

signed_token = sign_token(
  private_key_string=private_key_string,
  unsigned_token=unsigned_token
)

self_signed_token = make_packet(
  unsigned_token=unsigned_token,
  signature=signed_token
)

json = {}
json["token"] = self_signed_token

response = requests.post(
  url="https://rest.smartvault.com/auto/auth/ctoken/1",
  headers=headers,
  json=json
  )

response_body = response.content
print(response_body)`;


export const requestClientTokenPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/auto/auth/ctoken/1");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_POST, TRUE);

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\"
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const requestClientTokenRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "token": "U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw=="
}'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.post 'https://rest.smartvault.com/auto/auth/ctoken/1', values, headers
puts response`;
export const requestClientTokenGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\"\\n}")

\treq, _ := http.NewRequest("POST", "https://rest.smartvault.com/auto/auth/ctoken/1", bytes.NewBuffer(body))

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const requestClientTokenC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\"}", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PostAsync("auto/auth/ctoken/1", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const requestClientTokenVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/auto/auth/ctoken/1"), System.Net.HttpWebRequest)

request.Method = "POST"

request.ContentType = "application/json"
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""token\\"": \\""U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\""
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const requestClientTokenGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "token": "U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw=="
}')
response = client.post( path : "/auto/auth/ctoken/1",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const requestClientTokenObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/auto/auth/ctoken/1"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"POST"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\"\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const requestClientTokenSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/auto/auth/ctoken/1")!
var request = URLRequest(url: url)
request.httpMethod = "POST"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"token\\": \\"U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw==\\"\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// REQUEST DELEGATION TOKEN
export const requestDelegationTokenRaw = `Headers:

Content-Type:application/json
Accept:application/json
Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==

Body:

{
  "user_email": "test@smartvault.com"
}`;
export const requestDelegationTokenCurl = `curl --include \\
     --request POST \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
     --header "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" \\
     --data-binary "{
    \\"user_email\\": \\"test@smartvault.com\\"
}" \\
'https://rest.smartvault.com/auto/auth/dtoken/1'`;
export const requestDelegationTokenJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  \\"user_email\\": \\"test@smartvault.com\\"}");
Response response = client.target("https://rest.smartvault.com/auto/auth/dtoken/1")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
  .header("Accept", "application/json")
  .post(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const requestDelegationTokenJavascript = `var request = new XMLHttpRequest();

request.open('POST', 'https://rest.smartvault.com/auto/auth/dtoken/1');

request.setRequestHeader('Authorization', 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==');
request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {
  'user_email': 'test@smartvault.com'
};

request.send(JSON.stringify(body));`;
export const requestDelegationTokenNode = `var request = require('request');

request({
  method: 'POST',
  url: 'https://rest.smartvault.com/auto/auth/dtoken/1',
  headers: {
    'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  body: "{  \\"user_email\\": \\"test@smartvault.com\\"}"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const requestDelegationTokenPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "user_email": "test@smartvault.com"}';

$ua->default_header("Authorization" => "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/auto/auth/dtoken/1", Content => $data);

print $response->as_string;`;
export const requestDelegationTokenPython = `from urllib2 import Request, urlopen

values = """
  {
    "user_email": "test@smartvault.com"
  }
"""

headers = {
  'Authorization': 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/auto/auth/dtoken/1', data=values, headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const requestDelegationTokenPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/auto/auth/dtoken/1");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_POST, TRUE);

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"user_email\\": \\"test@smartvault.com\\"
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Authorization: Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==",
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const requestDelegationTokenRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "user_email": "test@smartvault.com"
}'

headers = {
  :authorization => 'Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==',
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.post 'https://rest.smartvault.com/auto/auth/dtoken/1', values, headers
puts response`;
export const requestDelegationTokenGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"user_email\\": \\"test@smartvault.com\\"\\n}")

\treq, _ := http.NewRequest("POST", "https://rest.smartvault.com/auto/auth/dtoken/1", bytes.NewBuffer(body))

\treq.Header.Add("Authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const requestDelegationTokenC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==");
  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"user_email\\": \\"test@smartvault.com\\"}", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PostAsync("auto/auth/dtoken/1", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const requestDelegationTokenVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/auto/auth/dtoken/1"), System.Net.HttpWebRequest)

request.Method = "POST"

request.ContentType = "application/json"
request.Headers.Add("authorization", "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==")
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""user_email\\"": \\""test@smartvault.com\\""
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const requestDelegationTokenGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Authorization" = "Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ=="
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "user_email": "test@smartvault.com"
}')
response = client.post( path : "/auto/auth/dtoken/1",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const requestDelegationTokenObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/auto/auth/dtoken/1"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"POST"];

[request setValue:@"Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==" forHTTPHeaderField:@"Authorization"];
[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"user_email\\": \\"test@smartvault.com\\"\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const requestDelegationTokenSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/auto/auth/dtoken/1")!
var request = URLRequest(url: url)
request.httpMethod = "POST"
request.addValue("Basic dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==", forHTTPHeaderField: "Authorization")
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"user_email\\": \\"test@smartvault.com\\"\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// REQUEST PIN
export const requestPinRaw = `Headers:

Content-Type:application/json
Accept:application/json

Body:

{
  "client_id": "SampleCRMWeb"
}
`;
export const requestPinCurl = `curl --include \\
     --request POST \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
     --data-binary "{ \\"client_id\\": \\"SampleCRMWeb\\" }" \\
'https://rest.smartvault.com/pin/end'`;
export const requestPinJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  \\"client_id\\": \\"SampleCRMWeb\\"}");
Response response = client.target("https://rest.smartvault.com/pin/end")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .post(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const requestPinJavascript = `var request = new XMLHttpRequest();

request.open('POST', 'https://rest.smartvault.com/pin/end');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {
  'client_id': 'SampleCRMWeb'
};

request.send(JSON.stringify(body));`;
export const requestPinNode = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "client_id": "SampleCRMWeb"}';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/pin/end", Content => $data);

print $response->as_string;`;
export const requestPinPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "client_id": "SampleCRMWeb"}';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/pin/end", Content => $data);

print $response->as_string;`;
export const requestPinPython = `from urllib2 import Request, urlopen

values = """
  {
    "client_id": "SampleCRMWeb"
  }
"""

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/pin/end', data=values, headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const requestPinPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/pin/end");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_POST, TRUE);

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"client_id\\": \\"SampleCRMWeb\\"
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const requestPinRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "client_id": "SampleCRMWeb"
}'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.post 'https://rest.smartvault.com/pin/end', values, headers
puts response`;
export const requestPinGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"client_id\\": \\"SampleCRMWeb\\"\\n}")

\treq, _ := http.NewRequest("POST", "https://rest.smartvault.com/pin/end", bytes.NewBuffer(body))

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const requestPinC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"client_id\\": \\"SampleCRMWeb\\"}", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PostAsync("pin/end", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const requestPinVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/pin/end"), System.Net.HttpWebRequest)

request.Method = "POST"

request.ContentType = "application/json"
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""client_id\\"": \\""SampleCRMWeb\\""
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const requestPinGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "client_id": "SampleCRMWeb"
}')
response = client.post( path : "/pin/end",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const requestPinObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/pin/end"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"POST"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"client_id\\": \\"SampleCRMWeb\\"\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const requestPinSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/pin/end")!
var request = URLRequest(url: url)
request.httpMethod = "POST"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"client_id\\": \\"SampleCRMWeb\\"\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;

// REQUEST PIN DELEGATION TOKEN
export const requestPinDelegationTokenRaw = `Headers:

Content-Type:application/json
Accept:application/json

Body:

{
  "user_email": "testuser@smartvault.com",
  "pin_info": {
    "client_id": "SampleCRMWeb",
    "pin": 341450484
  }
}
`;
export const requestPinDelegationTokenCurl = `curl --include \\
     --request POST \\
     --header "Content-Type: application/json" \\
     --header "Accept: application/json" \\
     --data-binary "{
    \\"user_email\\": \\"testuser@smartvault.com\\",
    \\"pin_info\\": {
        \\"client_id\\": \\"SampleCRMWeb\\",
        \\"pin\\": 341450484
    }
}" \\
'https://rest.smartvault.com/pin/auth'`;
export const requestPinDelegationTokenJava = `// Maven : Add these dependecies to your pom.xml (java6+)
// <dependency>
//     <groupId>org.glassfish.jersey.core</groupId>
//     <artifactId>jersey-client</artifactId>
//     <version>2.8</version>
// </dependency>
// <dependency>
//     <groupId>org.glassfish.jersey.media</groupId>
//     <artifactId>jersey-media-json-jackson</artifactId>
//     <version>2.8</version>
// </dependency>

import javax.ws.rs.client.Client;
import javax.ws.rs.client.ClientBuilder;
import javax.ws.rs.client.Entity;
import javax.ws.rs.core.Response;
import javax.ws.rs.core.MediaType;

Client client = ClientBuilder.newClient();
Entity payload = Entity.json("{  \\"user_email\\": \\"testuser@smartvault.com\\",  \\"pin_info\\": {    \\"client_id\\": \\"SampleCRMWeb\\",    \\"pin\\": 341450484  }}");
Response response = client.target("https://rest.smartvault.com/pin/auth")
  .request(MediaType.APPLICATION_JSON_TYPE)
  .header("Accept", "application/json")
  .post(payload);

System.out.println("status: " + response.getStatus());
System.out.println("headers: " + response.getHeaders());
System.out.println("body:" + response.readEntity(String.class));`;
export const requestPinDelegationTokenJavascript = `var request = new XMLHttpRequest();

request.open('POST', 'https://rest.smartvault.com/pin/auth');

request.setRequestHeader('Content-Type', 'application/json');
request.setRequestHeader('Accept', 'application/json');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    console.log('Status:', this.status);
    console.log('Headers:', this.getAllResponseHeaders());
    console.log('Body:', this.responseText);
  }
};

var body = {
  'user_email': 'testuser@smartvault.com',
  'pin_info': {
    'client_id': 'SampleCRMWeb',
    'pin': 341450484
  }
};

request.send(JSON.stringify(body));`;
export const requestPinDelegationTokenNode = `var request = require('request');

request({
  method: 'POST',
  url: 'https://rest.smartvault.com/pin/auth',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  body: "{  \\"user_email\\": \\"testuser@smartvault.com\\",  \\"pin_info\\": {    \\"client_id\\": \\"SampleCRMWeb\\",    \\"pin\\": 341450484  }}"
}, function (error, response, body) {
  console.log('Status:', response.statusCode);
  console.log('Headers:', JSON.stringify(response.headers));
  console.log('Response:', body);
});`;
export const requestPinDelegationTokenPerl = `$ENV{'PERL_LWP_SSL_VERIFY_HOSTNAME'} = 0;
use LWP::UserAgent;
use strict;
use warnings;
use 5.010;
use Cpanel::JSON::XS qw(encode_json decode_json);

my $ua   = LWP::UserAgent->new;
my $data = '{  "user_email": "testuser@smartvault.com",  "pin_info": {    "client_id": "SampleCRMWeb",    "pin": 341450484  }}';

$ua->default_header("Content-Type" => "application/json");
$ua->default_header("Accept" => "application/json");

my $response = $ua->post("https://rest.smartvault.com/pin/auth", Content => $data);

print $response->as_string;`;
export const requestPinDelegationTokenPython = `from urllib2 import Request, urlopen

values = """
  {
    "user_email": "testuser@smartvault.com",
    "pin_info": {
      "client_id": "SampleCRMWeb",
      "pin": 341450484
    }
  }
"""

headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}
request = Request('https://rest.smartvault.com/pin/auth', data=values, headers=headers)

response_body = urlopen(request).read()
print response_body`;
export const requestPinDelegationTokenPhp = `<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "https://rest.smartvault.com/pin/auth");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_HEADER, FALSE);

curl_setopt($ch, CURLOPT_POST, TRUE);

curl_setopt($ch, CURLOPT_POSTFIELDS, "{
  \\"user_email\\": \\"testuser@smartvault.com\\",
  \\"pin_info\\": {
    \\"client_id\\": \\"SampleCRMWeb\\",
    \\"pin\\": 341450484
  }
}");

curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  "Content-Type: application/json",
  "Accept: application/json"
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);`;
export const requestPinDelegationTokenRuby = `require 'rubygems' if RUBY_VERSION < '1.9'
require 'rest_client'

values = '{
  "user_email": "testuser@smartvault.com",
  "pin_info": {
    "client_id": "SampleCRMWeb",
    "pin": 341450484
  }
}'

headers = {
  :content_type => 'application/json',
  :accept => 'application/json'
}

response = RestClient.post 'https://rest.smartvault.com/pin/auth', values, headers
puts response`;
export const requestPinDelegationTokenGo = `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {
\tclient := &http.Client{}

\tbody := []byte("{\\n  \\"user_email\\": \\"testuser@smartvault.com\\",\\n  \\"pin_info\\": {\\n    \\"client_id\\": \\"SampleCRMWeb\\",\\n    \\"pin\\": 341450484\\n  }\\n}")

\treq, _ := http.NewRequest("POST", "https://rest.smartvault.com/pin/auth", bytes.NewBuffer(body))

\treq.Header.Add("Content-Type", "application/json")
\treq.Header.Add("Accept", "application/json")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Errored when sending request to the server")
\t\treturn
\t}

\tdefer resp.Body.Close()
\tresp_body, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println(resp.Status)
\tfmt.Println(string(resp_body))
}`;
export const requestPinDelegationTokenC = `//Common testing requirement. If you are consuming an API in a sandbox/test region, uncomment this line of code ONLY for non production uses.
//System.Net.ServicePointManager.ServerCertificateValidationCallback = delegate { return true; };

//Be sure to run "Install-Package Microsoft.Net.Http" from your nuget command line.
using System;
using System.Net.Http;

var baseAddress = new Uri("https://rest.smartvault.com/");

using (var httpClient = new HttpClient{ BaseAddress = baseAddress })
{

  
  httpClient.DefaultRequestHeaders.TryAddWithoutValidation("accept", "application/json");
  
    using (var content = new StringContent("{  \\"user_email\\": \\"testuser@smartvault.com\\",  \\"pin_info\\": {    \\"client_id\\": \\"SampleCRMWeb\\",    \\"pin\\": 341450484  }}", System.Text.Encoding.Default, "application/json"))
    {
      using (var response = await httpClient.PostAsync("pin/auth", content))
      {
        string responseData = await response.Content.ReadAsStringAsync();
      }
  }
}`;
export const requestPinDelegationTokenVB = `Dim request = TryCast(System.Net.WebRequest.Create("https://rest.smartvault.com/pin/auth"), System.Net.HttpWebRequest)

request.Method = "POST"

request.ContentType = "application/json"
request.Accept = "application/json"

Using writer = New System.IO.StreamWriter(request.GetRequestStream())
  Dim byteArray As Byte() = System.Text.Encoding.UTF8.GetBytes("{
  \\""user_email\\"": \\""testuser@smartvault.com\\"",
  \\""pin_info\\"": {
    \\""client_id\\"": \\""SampleCRMWeb\\"",
    \\""pin\\"": 341450484
  }
}")
  request.ContentLength = byteArray.Length
  writer.Write(byteArray)
  writer.Close()
End Using
Dim responseContent As String
Using response = TryCast(request.GetResponse(), System.Net.HttpWebResponse)
  Using reader = New System.IO.StreamReader(response.GetResponseStream())
    responseContent = reader.ReadToEnd()
  End Using
End Using`;
export const requestPinDelegationTokenGroovy = `import groovyx.net.http.RESTClient
import static groovyx.net.http.ContentType.JSON
import groovy.json.JsonSlurper
import groovy.json.JsonOutput

@Grab (group = 'org.codehaus.groovy.modules.http-builder', module = 'http-builder', version = '0.5.0')
def client = new RESTClient("https://rest.smartvault.com")

def emptyHeaders = [:]
emptyHeaders."Content-Type" = "application/json"
emptyHeaders."Accept" = "application/json"

def jsonObj = new JsonSlurper().parseText('{
  "user_email": "testuser@smartvault.com",
  "pin_info": {
    "client_id": "SampleCRMWeb",
    "pin": 341450484
  }
}')
response = client.post( path : "/pin/auth",
                        body : jsonObj,
                        headers: emptyHeaders,
                        contentType : JSON )

println("Status:" + response.status)

if (response.data) {
  println("Content Type: " + response.contentType)
  println("Body:\\n" + JsonOutput.prettyPrint(JsonOutput.toJson(response.data)))
}`;
export const requestPinDelegationTokenObjectiveC = `NSURL *URL = [NSURL URLWithString:@"https://rest.smartvault.com/pin/auth"];

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:URL];
[request setHTTPMethod:@"POST"];

[request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
[request setValue:@"application/json" forHTTPHeaderField:@"Accept"];

[request setHTTPBody:[@"{\\n  \\"user_email\\": \\"testuser@smartvault.com\\",\\n  \\"pin_info\\": {\\n    \\"client_id\\": \\"SampleCRMWeb\\",\\n    \\"pin\\": 341450484\\n  }\\n}" dataUsingEncoding:NSUTF8StringEncoding]];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *task = [session dataTaskWithRequest:request
                                        completionHandler:
                              ^(NSData *data, NSURLResponse *response, NSError *error) {

                                  if (error) {
                                      // Handle error...
                                      return;
                                  }

                                  if ([response isKindOfClass:[NSHTTPURLResponse class]]) {
                                      NSLog(@"Response HTTP Status code: %ld\\n", (long)[(NSHTTPURLResponse *)response statusCode]);
                                      NSLog(@"Response HTTP Headers:\\n%@\\n", [(NSHTTPURLResponse *)response allHeaderFields]);
                                  }

                                  NSString* body = [[NSString alloc] initWithData:data encoding:NSUTF8StringEncoding];
                                  NSLog(@"Response Body:\\n%@\\n", body);
                              }];
[task resume];`;
export const requestPinDelegationTokenSwift = `import Foundation

// NOTE: Uncommment following two lines for use in a Playground
// import PlaygroundSupport
// PlaygroundPage.current.needsIndefiniteExecution = true

let url = URL(string: "https://rest.smartvault.com/pin/auth")!
var request = URLRequest(url: url)
request.httpMethod = "POST"
request.addValue("application/json", forHTTPHeaderField: "Content-Type")
request.addValue("application/json", forHTTPHeaderField: "Accept")

request.httpBody = """
"{\\n  \\"user_email\\": \\"testuser@smartvault.com\\",\\n  \\"pin_info\\": {\\n    \\"client_id\\": \\"SampleCRMWeb\\",\\n    \\"pin\\": 341450484\\n  }\\n}"
""".data(using: .utf8)

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  if let response = response {
    print(response)

    if let data = data, let body = String(data: data, encoding: .utf8) {
      print(body)
    }
  } else {
    print(error ?? "Unknown error")
  }
}

task.resume()`;
